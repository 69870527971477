import AllImages from "./AllImages";

export const activeDrawingCardInfo = [
  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "69:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "60:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "59:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "59:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "62:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "46:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },

  {
    car_logo: AllImages.mercedes_logo,
    car_image: AllImages.mercedes_img,
    manufacture_year: "2023",
    car_make: "Mercedes-Benz",
    car_model: "GLE SUV",
    condition: "Brand New",
    mileage: "22323",
    color: "white",
    engine_size: "2833773",
    registered_city: "Not Registered",
    start_time: "2023-11-31T23:59:59",
    current_entry: "343",
    entry_ends: "12th Feb, 2023",
    draw_time: "59:15:23",
    description:
      "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",

    other_photo: [
      AllImages.image_one,
      AllImages.image_two,
      AllImages.image_three,
      AllImages.image_four,
    ],
  },
];

export const pastDrawingCardInfo = [
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "209276292",
  //   total_entries: "78,972",
  //   drawing_date: "12th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   mileage: "22323",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "3739276292",
  //   total_entries: "78,972",
  //   drawing_date: "10th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   mileage: "22323",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "377309276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   mileage: "22323",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "38309276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   mileage: "22323",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "38309276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   mileage: "22323",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "209276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   mileage: "22323",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "209276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   mileage: "22323",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
  // {
  //   car_logo: AllImages.mercedes_logo,
  //   car_image: AllImages.mercedes_img,
  //   winner_id: "209276292",
  //   total_entries: "78,972",
  //   drawing_date: "14th Feb, 2023",
  //   manufacture_year: "2023",
  //   color: "white",
  //   engine_size: "2833773",
  //   end_date: "10th Dec, 2023",
  //   registered_city: "Not Registered",
  //   car_make: "Mercedes-Benz",
  //   car_model: "GLE SUV",
  //   condition: "Brand New",
  //   mileage: "22323",
  //   description:
  //     "The refreshed GLE, with its striking looks inside and out, receives a host of versatile new technologies. With electrified engines and luxurious new appointments, the GLE redefines the luxury SUV to its core",
  //   other_photo: [
  //     AllImages.image_one,
  //     AllImages.image_two,
  //     AllImages.image_three,
  //     AllImages.image_four,
  //   ],
  // },
];
