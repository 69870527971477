import React from "react";
import MaterialTable, { Column } from "@material-table/core";
import { Input } from "@material-ui/core";

const TableComp = ({ tableColumn, tableData, handleRowClick }) => {
  const styling = {
    // border: "5px solid white",
    // boxShadow: "3px 3px 0 7px white",
    height: "100%",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "200",
  };

  return (
    <div>
      {" "}
      <MaterialTable
        title=""
        columns={tableColumn}
        data={tableData}
        style={styling}
        // components={{
        //   Header: (props) => (
        //     <div>
        //       <div style={{ marginBottom: "10px" }}>
        //         <Input
        //           placeholder="Filter"
        //           onChange={(e) => props.onFilterChanged(e.target.value)}
        //           style={{ marginLeft: "8px" }}
        //         />
        //       </div>
        //     </div>
        //   ),
        // }}
        options={{
          headerStyle: {
            borderRadius: "2px",
            background: "#141414",
            fontWeight: 300,
            fontSize: "14px",
            color: "#fff",
            height: "51px",
            // cursor: "none",

            // border: '1px solid black',
            wordWrap: "break-word",
            // width: 'auto'
          },
          rowStyle: {
            height: 80,
            width: "auto",
            wordWrap: "break-word",
            borderBottom: "1px solid #383838",
          },
          actionsColumnIndex: -1,
          paging: false,
          // pageSize: 10,
          tableLayout: "fixed",
          search: false, // Set search to false to remove the search functionality

          // paginationStyle: {
          //   // Customize pagination styles here
          //   root: {
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     margin: "10px",
          //   },
          //   toolbar: {
          //     backgroundColor: "#e0e0e0",
          //   },
          //   iconButton: {
          //     color: "#fff",
          //   },
          //   icon: {
          //     color: "#fff",
          //   },
          //   activeButton: {
          //     color: "blue",
          //   },
          // },
        }}
        onRowClick={handleRowClick} // Add this line to make rows clickable
      />
    </div>
  );
};

export default TableComp;
