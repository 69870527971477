import React, { useState, useEffect } from "react";
import AllImages from "../../utils/dataList/AllImages";
import "./carBrandSec.css";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, x: 100 },
};

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 50 },
};

const CarBrandSec = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="carbrand_flex_genContt" style={{ width: "100%" }}>
      <div className="carBrand_gradient_line"></div>

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsX}
        className="carbrand_Flex_image"
      >
        <div className="carBrand_image_class">
          <img src={AllImages.chevrolet} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.honda} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.benz} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.toyota} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.ford} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.dodge} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.kia} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.crysler} />
        </div>
        <div className="carBrand_image_class">
          <img src={AllImages.lincoln} />
        </div>
      </motion.div>

      <div className="carBrand_gradient_line"></div>
    </div>
  );
};

export default CarBrandSec;
