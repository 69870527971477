import React, { useState, useEffect } from "react";
import "./supportScreen.css";
import HomeSideMargin from "../../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../../containers/header/Header";
import Footer from "../../../containers/footer/Footer";
import TextInput from "../../../containers/textInput/TextInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";
import { useLocation } from "react-router-dom";

const SupportScreen = () => {
  const [drawInfo, setDrawInfo] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrawInfo({
      ...drawInfo,
      [name]: value,
    });
  };

  const handleSubmit = () => {};
  return (
    <div>
      <HomeSideMargin>
        <Header />
        <div className="support_orderrr">
          <div className="support_fieldCont">
            <div className="support_gradient___">
              <div className="support_policyGenCont">
                Contact Our Support Service
              </div>

              <form>
                <TextInput
                  inputLabel="Full Name"
                  inputName="fullName"
                  inputOnchange={handleChange}
                  inputPlaceholder="Enter full name"
                  inputType="text"
                  inputValue={drawInfo.fullName}
                  required={true}
                />
                <TextInput
                  inputLabel="Email Address"
                  inputName="email"
                  inputOnchange={handleChange}
                  inputPlaceholder="Enter email address"
                  inputType="email"
                  inputValue={drawInfo.email}
                  required={true}
                />

                <div className="message_bodyyyyyy">
                  <div className="message_labelll">Message</div>

                  <div className="message_inputtttt">
                    <textarea
                      placeholder="Message"
                      onChange={handleChange}
                      value={drawInfo.message}
                      name="message"
                      required={true}
                    />
                  </div>
                </div>

                <DefaultLoginBtn
                  width="100%"
                  btnText="Submit"
                  handleBtnClick={handleSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </HomeSideMargin>

      <Footer />
    </div>
  );
};

export default SupportScreen;
