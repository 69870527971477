import React, { useEffect } from "react";
import "./termsCondition.css";
import HomeSideMargin from "../../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../../containers/header/Header";
import Footer from "../../../containers/footer/Footer";
import { useNavigate, useLocation } from "react-router";

const TermsCondition = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handlePrivacyPolicy = () => {
    navigate("/privacy_policy");
  };
  return (
    <div>
      <HomeSideMargin>
        <Header />
        <div className="privacy_policyGenCont">TERMS AND CONDITIONS OF USE</div>
        <div className="priccc">ACCEPTANCE OF TERMS</div>

        <div className="egerere">
          By accessing and using this website , you agree to comply with and be
          bound by these terms and conditions of use. If you do not agree to
          these Terms, please do not use the Site.
        </div>

        <div className="priccc">CHANGES TO TERMS</div>

        <div className="egerere">
          We reserve the right to modify or replace these Terms at any time
          without prior notice. Your continued use of the Site after any changes
          constitutes acceptance of the new Terms.
        </div>

        <div className="priccc">USER CONDUCT</div>

        <div className="egerere">
          You agree not to engage in any of the following activities:
        </div>

        <div className="egerere">
          Violating applicable laws, regulations, or ordinances.
        </div>

        <div className="egerere">
          Interfering with the proper functioning of the Site.
        </div>

        <div className="egerere">
          Uploading, transmitting, or distributing any harmful, unlawful, or
          inappropriate content.
        </div>
        <div className="egerere">
          Attempting to gain unauthorized access to any part of the Site.
        </div>

        <div className="priccc">ACCEPTANCE OF TERMS</div>

        <div className="egerere">
          By accessing and using this website , you agree to comply with and be
          bound by these terms and conditions of use. If you do not agree to
          these Terms, please do not use the Site.
        </div>

        <div className="priccc">INTELLECTUAL PROPERTY</div>

        <div className="egerere">
          All content on the Site, including but not limited to text, images,
          graphics, logos, and software, is the property of MyCarrus LLC and is
          protected by intellectual property laws. You may not use, reproduce,
          or distribute any content from the Site without prior written
          permission.
        </div>

        <div className="priccc">DISCLAIMER OF WARRANTIES</div>

        <div className="egerere">
          The Site is provided "as is" without any warranties, express or
          implied. MyCarrus LLC makes no representations or warranties regarding
          the accuracy, completeness, or reliability of the content.
        </div>

        <div className="priccc">LIMITATION OF LIABILITY</div>

        <div className="egerere">
          MyCarrus LLC shall not be liable for any direct, indirect, incidental,
          special, or consequential damages arising out of or in any way
          connected with the use of the Site.
        </div>

        <div className="priccc">LINKS TO THIRD-PARTY SITES</div>

        <div className="egerere">
          The Site may contain links to third-party websites. MyCarrus LLC is
          not responsible for the content or privacy practices of these sites.
        </div>

        <div className="priccc">PRIVACY POLICY</div>

        <div className="egerere">
          Your use of the Site is also governed by our Privacy Policy, which can
          be found <span onClick={handlePrivacyPolicy}> here.</span>
        </div>

        <div className="priccc">CONTACT INFORMATION</div>

        <div className="egerere">
          If you have any questions about these Terms, please contact us at
          info@mycarrus.com
        </div>
      </HomeSideMargin>
      <Footer />
    </div>
  );
};

export default TermsCondition;
