import React, { useState, useEffect } from "react";
import "./activeDrawingEach.css";
import { axiosCalls, axiosFormData } from "../../_api";
import { hideLoader, showLoader } from "../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveDrawingEach = ({
  car_logo,
  car_image,
  draw_time,
  current_entry,
  end_date,
  handleOpenEachActiveDrawing,
  eachIndex,
}) => {
  const [formData, setFormData] = useState({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  //   const handleLogoChange = (event) => {
  //     const selectedImage = event.target.files[0];

  //     if (selectedImage) {
  //       setDrawInfo({
  //         ...drawInfo,
  //         carLogo: selectedImage,
  //       });
  //     }
  //   };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Function to format time
  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    return {
      hours: parseInt(hours),
      minutes: parseInt(minutes),
      seconds: parseInt(seconds),
    };
  };

  // Use the formatted time in your JSX
  const formattedTime = formatTime(draw_time);

  const handleUpdateDateDrawingForm = async (e) => {
    // e.preventDefault();
    // Convert start and end dates to JavaScript Date objects
    const startDate = new Date(`${formData.startDate}T${formData.startTime}`);
    const endDate = new Date(`${formData.endDate}T${formData.endTime}`);

    // Check if end date is less than start date
    if (endDate < startDate) {
      toast.error("End date must be greater than or equal to start date", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const startDateTimeString = new Date(
      `${formData.startDate}T${formData.startTime}`
    ).toISOString();

    const endDateTimeString = new Date(
      `${formData.endDate}T${formData.endTime}`
    ).toISOString();
    // e.preventDefault();
    const data = {
      start_date: startDateTimeString,
      end_date: endDateTimeString,
    };

    console.log(data, "6636633");

    showLoader();
    const res = await axiosFormData(
      `api/v1/entry/update/${eachIndex}`,
      "PUT",
      data
    );

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.message);
        toast.error("Error, Please Try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("Updated successfully", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      //   setTimeout(() => {
      //     window.location.href = "/admin/drawings";
      //   }, 2000);
    }
  };

  return (
    <div
      className="active_each_card_body"
      onClick={handleOpenEachActiveDrawing}
    >
      <div className="active_each_card_body_child">
        <div className="active_each_card_car_logo">
          <img src={car_logo} />
        </div>

        <div className="active_each_card_car_img">
          <img src={car_image} />
        </div>

        <div className="active_each_card_time_body">
          <div className="active_each_card_time_card">
            <div className="active_each_card_time_hour">
              {formattedTime.hours}
            </div>
            <div className="active_each_card_time_hourText">Hour</div>
          </div>
          <div className="active_each_card_time_columnText">:</div>
          <div className="active_each_card_time_card">
            <div className="active_each_card_time_hour">
              {formattedTime.minutes}
            </div>
            <div className="active_each_card_time_hourText">Mins</div>
          </div>
          <div className="active_each_card_time_columnText">:</div>
          <div className="active_each_card_time_card">
            <div className="active_each_card_time_hour">
              {formattedTime.seconds}
            </div>
            <div className="active_each_card_time_hourText">Secs</div>
          </div>
        </div>

        <div className="active_each_edit_time_SSS">
          Edit Drawing Starts Time
        </div>

        <div className="profile_tab_image_dateBody">
          <input
            type="date"
            className="active_drawing_date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
          />
        </div>
        <div className="profile_tab_image_timeBody">
          <input
            type="time"
            name="startTime"
            class="active_drawing_time"
            value={formData.startTime}
            onChange={handleChange}
          />
        </div>

        <div className="active_each_edit_time_SSS">Edit Drawing End Time</div>

        <div className="profile_tab_image_dateBody">
          <input
            type="date"
            className="active_drawing_date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>
        <div className="profile_tab_image_timeBody">
          <input
            type="time"
            name="endTime"
            class="active_drawing_time"
            value={formData.endTime}
            onChange={handleChange}
          />
        </div>

        <div
          onClick={(e) => {
            e.preventDefault();
            handleUpdateDateDrawingForm();
          }}
          className="each_active_date_update"
        >
          Update Date & Time
        </div>

        <div className="active_each_sub_card_body">
          <div className="active_each_sub_card_current">
            <div className="active_each_sub_card_entry">Current Entry:</div>
            <div className="active_each_sub_card_users">
              {current_entry} Users
            </div>
          </div>
          <div className="active_each_sub_card_current">
            <div className="active_each_sub_card_entry">Entry Ends:</div>
            <div className="active_each_sub_card_users">{end_date}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveDrawingEach;
