import React from "react";
import "./backToLogin.css";
import AllImages from "../../utils/dataList/AllImages";
import { useNavigate } from "react-router-dom";

const BackToLogin = () => {
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    navigate("/admin/login");
  };
  return (
    <div onClick={handleLoginNavigate} className="forgotPass__Footergen">
      <div className="forgotPass_backImg">
        <img src={AllImages.loginBack} />
      </div>
      <div className="forgotPass_backtext">Back to Log in</div>
    </div>
  );
};

export default BackToLogin;
