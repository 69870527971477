import React from "react";
import "./newModal.css";
import AuthScreenParent from "../authScreenParent/AuthScreenParent";

const NewModal = ({ children, closeNewModal }) => {
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  return (
    <div className="newModal-genBody" onClick={closeNewModal}>
      <div onClick={handleChildClick} className="newModal-Content">
        <AuthScreenParent>{children}</AuthScreenParent>
      </div>
    </div>
  );
};

export default NewModal;
