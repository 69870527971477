import React, { useState, useEffect } from "react";
import "./createDrawingForm.css";
import InfoModal from "../infoModal/InfoModal";
import AllImages from "../../utils/dataList/AllImages";
import TextInput from "../textInput/TextInput";
import { axiosCalls, axiosFormData } from "../../_api";
import { hideLoader, showLoader } from "../../loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateDrawingForm = ({ handleCloseDrawingForm }) => {
  const [image, setImage] = useState(null);
  const [drawInfo, setDrawInfo] = useState({
    image: null,
    carLogo: null,
    startTime: "",
    startDate: "",
    endTime: "",
    endDate: "",
    carMake: "",
    carModel: "",
    condition: "",
    mileage: 0,
    makeYear: "",
    colour: "",
    engineSize: "",
    registeredCity: "",
    carOverview: "",
  });

  // const handleImageChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     // Use FileReader to read the selected image as a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDrawInfo({
  //         ...drawInfo,
  //         image: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(selectedImage);
  //   }
  // };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setDrawInfo({
        ...drawInfo,
        image: selectedImage,
      });
    }
  };

  // const handleLogoChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     // Use FileReader to read the selected image as a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDrawInfo({
  //         ...drawInfo,
  //         carLogo: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(selectedImage);
  //   }
  // };

  const handleLogoChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setDrawInfo({
        ...drawInfo,
        carLogo: selectedImage,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrawInfo({
      ...drawInfo,
      [name]: value,
    });
  };

  const handleCreateDrawingForm = async (e) => {
    // e.preventDefault();
    // Convert start and end dates to JavaScript Date objects
    const startDate = new Date(`${drawInfo.startDate}T${drawInfo.startTime}`);
    const endDate = new Date(`${drawInfo.endDate}T${drawInfo.endTime}`);

    // Check if end date is less than start date
    if (endDate < startDate) {
      toast.error("End date must be greater than or equal to start date", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const startDateTimeString = new Date(
      `${drawInfo.startDate}T${drawInfo.startTime}`
    ).toISOString();

    const endDateTimeString = new Date(
      `${drawInfo.endDate}T${drawInfo.endTime}`
    ).toISOString();
    // e.preventDefault();
    const data = {
      car_photo: drawInfo.image,
      brand_logo: drawInfo.carLogo,
      car_make: drawInfo.carMake,
      start_date: startDateTimeString,
      end_date: endDateTimeString,
      car_model: drawInfo.carModel,
      condition: drawInfo.condition,
      manufacture_year: drawInfo.makeYear,
      color: drawInfo.colour,
      description: drawInfo.carOverview,
      mileage: drawInfo.mileage,
      engine_size: drawInfo.engineSize,
      registered_city: drawInfo.registeredCity,
    };

    // Create a new FormData object
    // const formDataObject = new FormData();

    // Append form data to the FormData object
    // Object.entries(data).forEach(([key, value]) => {
    //   formDataObject.append(key, value);
    // });
    showLoader();
    const res = await axiosFormData("api/v1/entry/create", "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.message);
        toast.error("Error, Please Try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("Created Draw successfully", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setTimeout(() => {
        window.location.href = "/admin/drawings";
      }, 2000);
    }
  };

  //   const handleCreateDrawingForm = async (e) => {
  //     e.preventDefault();
  //     const data = {
  //       car_photo: drawInfo.image,
  //       brand_logo: drawInfo.carLogo,
  //       car_make: drawInfo.carMake,
  //       start_date: drawInfo.startDate,
  //       start_time: drawInfo.startTime,
  //       end_date: drawInfo.endDate,
  //       end_time: drawInfo.endTime,
  //       car_model: drawInfo.carModel,
  //       condition: drawInfo.condition,
  //       manufacture_year: drawInfo.makeYear,
  //       color: drawInfo.colour,
  //       description: drawInfo.carOverview,
  //       mileage: drawInfo.mileage,
  //       engine_size: drawInfo.engineSize,
  //       registered_city: drawInfo.registeredCity,
  //     };

  //     showLoader();

  //     try {
  //       const res = await axiosFormData("api/v1/entry/create", "POST", data);
  //       if (res) {
  //         hideLoader();
  //         if (res.er) {
  //           toast.error(res?.er?.data?.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "dark",
  //           });
  //           console.log(res.er);
  //           return;
  //         }
  //         console.log(">>>>>>>>RES", res?.data);

  //         toast.success("Created Draw successfully", res?.data, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });

  //         return;
  //         // if (res?.data?.role == "superadmin") {
  //         //   console.log(res.data);
  //         //   localStorage.setItem("token", res?.data?.token);
  //         //   window.location.href = `/dashboard`;
  //         //   NotificationManager.success("Login successful", "Welcome admin");
  //         //   return;
  //         // }
  //         // NotificationManager.error("Permission denied", "Error");
  //       }
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }

  //     // history.push(`/dashboard`);
  //     // NotificationManager.success("Login successfull", "Welcom admin");
  //   };

  return (
    <InfoModal width="60%" handleCloseModal={handleCloseDrawingForm}>
      <div className="create_single_user_header">
        <div className="create_single_user_header_text">Create new drawing</div>
        <div
          onClick={handleCloseDrawingForm}
          className="create_single_user_header_btn"
        >
          X
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          //   console.log(drawInfo, "drawing data");

          handleCreateDrawingForm();
        }}
        className="create_single_user_form_body"
      >
        <div className="create_single_user_form_left">
          <div className="profile_tab_image_uploaded">
            <div className="profile_tab_image_image__">
              {" "}
              <img
                style={{
                  objectFit: "cover",
                  width: "101%",
                  borderRadius: "50%",
                }}
                src={drawInfo.image ? URL.createObjectURL(drawInfo.image) : ""}
                // src={drawInfo.image}
              />
            </div>
            <div className="profile_tab_image_upload_icon">
              <img src={AllImages.imageIcon} />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
          </div>

          <div className="profile_tab_image_displa_text">Display Picture</div>
          <div className="profile_tab_image_displa_text_sub">
            Must be a picture with transparent background
          </div>

          <div className="profile_tab_image_drawing_startText">
            Drawing Starts Time
          </div>
          <div className="profile_tab_image_dateBody">
            <input
              type="date"
              className="create_drawing_date"
              name="startDate"
              value={drawInfo.startDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="profile_tab_image_timeBody">
            <input
              type="time"
              name="startTime"
              class="create_drawing_time"
              value={drawInfo.startTime}
              onChange={handleChange}
              required
            />
          </div>

          <div className="profile_tab_image_drawing_startText">
            Drawing End Time
          </div>
          <div className="profile_tab_image_dateBody">
            <input
              type="date"
              className="create_drawing_date"
              name="endDate"
              value={drawInfo.endDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="profile_tab_image_timeBody">
            <input
              type="time"
              name="endTime"
              class="create_drawing_time"
              value={drawInfo.endTime}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="create_single_user_form_right">
          <div className="profile_tab_image_uploaded">
            <div className="profile_tab_image_image__">
              {" "}
              <img
                style={{
                  objectFit: "contain",
                  height: "100%",

                  width: "101%",
                  borderRadius: "50%",
                }}
                src={
                  drawInfo.carLogo ? URL.createObjectURL(drawInfo.carLogo) : ""
                }
                // src={drawInfo.carLogo}
              />
            </div>
            <div className="profile_tab_image_upload_icon">
              <img src={AllImages.imageIcon} />
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                required
              />
            </div>
          </div>

          <div className="profile_tab_image_displa_text">Brand Logo</div>
          <div className="profile_tab_image_displa_text_sub">
            Must be a picture with transparent background
          </div>

          <TextInput
            inputLabel="Car Make *"
            inputName="carMake"
            inputPlaceholder="Enter car make"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.carMake}
            width="100%"
            required={true}
          />

          <div className="create_single_user_form_flex_data">
            <TextInput
              inputLabel="Car Model *"
              inputName="carModel"
              inputPlaceholder="Enter car model"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.carModel}
              width="50%"
              required={true}
            />
            <TextInput
              inputLabel="Condition *"
              inputName="condition"
              inputPlaceholder="Enter car conditiom"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.condition}
              width="50%"
              required={true}
            />
          </div>

          <div className="create_single_user_form_flex_data">
            <TextInput
              inputLabel="Mileage *"
              inputName="mileage"
              inputPlaceholder="Enter car mileage"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.mileage}
              width="50%"
              required={true}
            />
            <TextInput
              inputLabel="Year of Manufacture *"
              inputName="makeYear"
              inputPlaceholder="Enter car year of manufature"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.makeYear}
              width="50%"
              required={true}
            />
          </div>

          <div className="create_single_user_form_flex_data">
            <TextInput
              inputLabel="Colour *"
              inputName="colour"
              inputPlaceholder="Enter car color"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.colour}
              width="50%"
              required={true}
            />
            <TextInput
              inputLabel="Engine Size *"
              inputName="engineSize"
              inputPlaceholder="Enter car engine size"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={drawInfo.engineSize}
              width="50%"
              required={true}
            />
          </div>

          <TextInput
            inputLabel="Registered City*"
            inputName="registeredCity"
            inputPlaceholder="Enter City"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.registeredCity}
            width="100%"
            required={true}
          />
          <TextInput
            inputLabel="Car Overview*"
            inputName="carOverview"
            inputPlaceholder="Enter car overview"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.carOverview}
            width="100%"
            required={true}
          />

          <button
            // onClick={(e) => {
            //   e.preventDefault();
            //   //   console.log(drawInfo, "drawing data");

            //   handleCreateDrawingForm();
            // }}
            className="create_single_user_form_data_btn"
            type="submit"
          >
            Create Drawing
          </button>
        </div>
      </form>
    </InfoModal>
  );
};

export default CreateDrawingForm;
