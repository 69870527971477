import React from "react";
import "./secondCompBody.css";
import AllImages from "../../utils/dataList/AllImages";

const SecondCompBody = ({ image, heading, paraText }) => {
  return (
    <div className="second_comp_bodyCont">
      <div className="second_comp_imgCont">
        <img src={image} />
      </div>

      <div className="second_comp_textOne">{heading}</div>

      <div className="second_comp_textTwo">{paraText}</div>
    </div>
  );
};

export default SecondCompBody;
