import React, { useEffect } from "react";
import "./privacyPolicy.css";
import Footer from "../../../containers/footer/Footer";
import HomeSideMargin from "../../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../../containers/header/Header";
import { useLocation, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleNavigateDeleteStep = () => {
    navigate("/delete-mobile-account");
  };
  return (
    <div>
      <HomeSideMargin>
        <Header />
        <div className="privacy_policyGenCont">Privacy Policy</div>
        <div className="priccc">Introduction</div>

        <div className="egerere">
          Personal Information: Information that can be used to identify you,
          such as your name, email address, or phone number.
          <br /> Log Data: Information collected automatically, such as your IP
          address, browser type, and pages visited on our website. <br />
          Cookies: We use cookies and similar technologies to improve your
          experience on our website.
        </div>

        <div className="priccc">Information We Collect</div>

        <div className="egerere">
          Personal Information: We may collect personal information such as your
          name, email address, and other contact details when you voluntarily
          provide it to us.
        </div>

        <div className="egerere">
          Device Information: We automatically collect certain information about
          your device, including the device model, operating system, unique
          device identifiers, and mobile network information.
        </div>

        <div className="egerere">
          Usage Information: We collect information about how you use the App,
          such as the features you access, the buttons you click, and the pages
          you view.
        </div>
        <div className="priccc">How We Use Your Information</div>

        <div className="egerere">To provide and maintain the App</div>
        <div className="egerere">
          To personalize your experience within the App
        </div>
        <div className="egerere">
          To improve the App and optimize user experience
        </div>
        <div className="egerere">
          To send you updates, newsletters, and promotional materials (if you've
          opted in)
        </div>
        <div className="egerere">
          To respond to your inquiries and provide customer support
        </div>

        <div className="priccc">Information Sharing</div>

        <div className="egerere">
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as described below:
        </div>

        <div className="egerere">
          Service Providers: We may share your information with third-party
          service providers who help us operate, improve, and maintain the App.
        </div>
        <div className="egerere">
          Legal Compliance: We may disclose your information to comply with
          applicable laws, regulations, or legal processes.
        </div>

        <div className="priccc">Security</div>

        <div className="egerere">
          We take reasonable measures to protect the security of your personal
          information. However, please note that no method of transmission over
          the internet or electronic storage is 100% secure.
        </div>

        <div className="priccc">Your Choices</div>

        <div className="egerere">
          Opt-Out: You can opt-out of receiving promotional emails by following
          the instructions in the email.{" "}
        </div>

        <div className="egerere">
          Device Settings: You can adjust your device settings to limit certain
          data collection.{" "}
        </div>
        <div className="egerere_dele">
          Delete Mobile App Account: You can delete your account created on the
          mobile app <span onClick={handleNavigateDeleteStep}>here</span>
        </div>
        <div className="priccc">Children's Privacy</div>

        <div className="egerere">
          The App is not intended for individuals under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and believe that your child has provided us
          with personal information, please contact us.
        </div>

        <div className="priccc">Changes to This Privacy Policy</div>

        <div className="egerere">
          We may update this Privacy Policy to reflect changes to our
          information practices. We encourage you to periodically review this
          page for the latest information on our privacy practices.
        </div>

        <div className="priccc">Contact Us</div>

        <div className="egerere">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at info@mycarrus.com
        </div>
      </HomeSideMargin>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
