import React from "react";
import "./forgotPasswordCheck.css";
import NewModal from "../../../containers/newModal/NewModal";
import AllImages from "../../../utils/dataList/AllImages";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";
import BackToLogin from "../../../containers/backToLogin/BackToLogin";
import { useNavigate } from "react-router-dom";

const ForgotPaswordCheck = ({ closeNewModal }) => {
  const navigate = useNavigate();

  const handleNavigateNewPass = () => {
    navigate("/admin/new_password");
  };
  return (
    <NewModal closeNewModal={closeNewModal}>
      <div className="forgotPasswordCheck_genCont">
        <img src={AllImages.checkEmail} />
      </div>

      <div className="forgotPasswordCheckText__">Check your email</div>

      <div className="forgotPasswordCheck_subtext__">
        We sent a email password n reset link to peter.omiwole@deralex.com
      </div>

      <DefaultLoginBtn
        handleBtnClick={handleNavigateNewPass}
        btnText="Open Email App"
      />

      <div className="forgotpassWordCheck_resend">
        Don’t receive the e-mail click to{" "}
        <span onClick={closeNewModal}>resend </span>
      </div>
      <BackToLogin />
    </NewModal>
  );
};

export default ForgotPaswordCheck;
