import React, { useState, useEffect } from "react";
import "./partners.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import TableComp from "../../../containers/table/TableComp";
import AddPartners from "../../../containers/addPartners/AddPartners";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";

const lookup = { true: "Active", false: "Deactivated" };

const Partners = () => {
  const [searchText, setSearchText] = useState("");

  const [tab, setTab] = useState(0);
  const [openDrawingForm, setOpenDrawingForm] = useState(false);
  const [userTableData, setUserTableData] = useState([]);

  const handleTabZero = () => {
    setTab(0);
  };

  const handleTabOne = () => {
    setTab(1);
  };

  const handleOpenDrawingForm = () => {
    setOpenDrawingForm(true);
  };

  const handleCloseDrawingForm = () => {
    setOpenDrawingForm(false);
  };

  const handleRowClick = (event, rowData) => {
    // Do something with the clicked row data, e.g., navigate to a detail page
    console.log("Clicked row data:", rowData);
  };

  const getUsersData = async () => {
    showLoader();

    const res = await axiosCalls("api/v1/site/config/partners", "GET");

    if (res) {
      // userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        return;
      }

      hideLoader();
      console.log(res, "ffff");

      const resData = res?.data?.content;
      // setUser(resData);
      // const jsonString = JSON.stringify(resData);
      // localStorage.setItem("Overview", jsonString);
      setUserTableData(resData);
      // Calculate the active user count
      // const activeUsers = resData.filter((user) => user.status === "ACTIVE");
      // setActiveUserCount(activeUsers.length);

      console.log(">>>>>>>>>>Res", resData?.content);
    }
  };

  useEffect(() => {
    getUsersData();
  }, []);

  // const data = [
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: false,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: false,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "James Redd",
  //     emailAddress: "james.re@gmail.com",
  //     phoneNumber: "1890",
  //     registeredDate: "5th, Oct, 2022",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Foxx David",
  //     emailAddress: "david.fo@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  //   {
  //     fullName: "Benjamin Thomas",
  //     emailAddress: "benj.re@gmail.com",
  //     phoneNumber: "1960",
  //     registeredDate: "5th, Oct, 2021",
  //     // status: "Active",
  //     status: false,
  //   },
  //   {
  //     fullName: "Daniel Regal",
  //     emailAddress: "daniel.re@gmail.com",
  //     phoneNumber: "1987989888",
  //     registeredDate: "5th, Oct, 2023",
  //     // status: "Active",
  //     status: true,
  //   },
  // ];

  const filteredData =
    tab === 0
      ? userTableData.filter(
          (row) =>
            row.status === "ACTIVE" &&
            (row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
              row.email.toLowerCase().includes(searchText.toLowerCase()))
        )
      : userTableData.filter(
          (row) =>
            row.status === "DELETED" ||
            (row.status === "PENDING" &&
              (row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                row.email.toLowerCase().includes(searchText.toLowerCase())))
        );

  const columns = [
    {
      title: "Partner's Logo",
      field: "img_url",

      // align: "center",
      render: (rowData) => (
        // <RowDetails
        //   text={rowData.name}
        //   detailsText={rowData.email}
        //   rowImg={rowData.img}
        // />

        <div className="managed_users_table_role_body">
          {rowData?.img_url !== null ? (
            <div className="manage_user_body_details_image_body">
              <div className="manage_user_body_details_image">
                {" "}
                <img
                  src={rowData.img_url}
                  alt={`Profile pic for ${rowData.name}`}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="manage_user_body_details_image_body">
              <div className="manage_user_body_details_image">
                {rowData.name // if profile_pic is null, show only the first letters of the names
                  .split(" ")
                  .map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
          )}
        </div>
      ),
    },
    { title: "Partner Brand Name", field: "name" },

    { title: "Email address", field: "emailAddress" },
    { title: "Phone number", field: "phoneNumber" },
    {
      title: "Registered Date",
      field: "createdAt",
      render: (rowData) => {
        const date = new Date(rowData.createdAt);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("en-GB", options);
      },
    },
    {
      title: "Status",
      field: "status",
      lookup,
      render: (rowData) => (
        <span style={{ color: rowData.status ? "green" : "red" }}>
          {lookup[rowData.status]}
        </span>
      ),
    },
  ];
  return (
    <AdminBodyMargin>
      <div className="registered_users_heading">Partners</div>

      <div className="registered_user_nav_body">
        <div className="registered_user_nav_absolute">
          <div className=" registered_user_nav_absoluteFlex_left">
            <div
              onClick={handleTabZero}
              className={
                tab === 0
                  ? "registered_user_act_text_active"
                  : "registered_user_act_text"
              }
            >
              Active Partners
            </div>
            <div
              onClick={handleTabOne}
              className={
                tab === 1
                  ? "registered_user_act_text_active"
                  : "registered_user_act_text"
              }
            >
              Non Active Partners
            </div>
          </div>

          <div
            onClick={handleOpenDrawingForm}
            className="drawings_user_nav_absoluteFlex_right"
          >
            Add Partners
          </div>
        </div>
      </div>

      {tab === 0 && (
        <div>
          <TableComp
            handleRowClick={handleRowClick}
            tableColumn={columns}
            tableData={userTableData}
          />
        </div>
      )}

      {tab === 1 && (
        <div>
          {" "}
          <TableComp
            handleRowClick={handleRowClick}
            tableColumn={columns}
            // tableData={filteredData}
          />
        </div>
      )}

      {openDrawingForm && (
        <AddPartners handleCloseDrawingForm={handleCloseDrawingForm} />
      )}
    </AdminBodyMargin>
  );
};

export default Partners;
