import React from "react";
import "./deleteMobileAccount.css";
import Footer from "../../../containers/footer/Footer";
import HomeSideMargin from "../../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../../containers/header/Header";
import AllImages from "../../../utils/dataList/AllImages";

const DeleteMobileAccount = () => {
  return (
    <div>
      <HomeSideMargin>
        <Header />

        <div className="privacy_policyGenCont">
          How To Delete Your Account on MyCarrus App
        </div>

        <div className="flex_delete_steps">
          <div>
            <div className="priccc">Step 1</div>

            <div className="steps__egerere">Select the "More" option</div>
            <div className="step_imagesll">
              <img src={AllImages.step_one} />
            </div>
          </div>

          <div>
            <div className="priccc">Step 2</div>

            <div className="steps__egerere">Select "Delete Account" option</div>
            <div className="step_imagesll">
              <img src={AllImages.step_two} />
            </div>
          </div>

          <div>
            <div className="priccc">Step 3</div>

            <div className="steps__egerere">
              Then finally select the "Delete Account" in the modal pop-up to
              confirm delete.
            </div>
            <div className="step_imagesll">
              <img src={AllImages.step_three} />
            </div>
          </div>
        </div>
      </HomeSideMargin>

      <Footer />
    </div>
  );
};

export default DeleteMobileAccount;
