import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import HomeSideMargin from "../homeSideMargin/HomeSideMargin";
import AllImages from "../../utils/dataList/AllImages";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import Burger from "../mobileNav/Burger";
import { useOnHoverOutside } from "../useOnHoverOutside'/useOnHoverOutside";

const Header = () => {
  const [show, setShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const handleSupportScreen = () => {
    navigate("/support");
  };

  const handleComingSoonScreen = () => {
    navigate("/comingsoon");
  };
  const splitLocation = pathname.split("/");

  const controlNavbar = () => {
    if (window.scrollY > 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };
  const openHoverMenu = () => {
    setMenuDropDownOpen(true);
  };
  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  return (
    <div>
      {screenWidth < 992 ? (
        <div className="mobilenavbar-optMenuFam">
          <Link
            to="/"
            className="mobilenavbar-optMenulogo"
            style={{ textDecoration: "none" }}
          >
            <img src={AllImages.whiteLogo} />
          </Link>
          <div>
            <Burger />
          </div>
        </div>
      ) : (
        <div className="header_gen_container">
          <Link
            to="/"
            className="header_logo_container"
            style={{ textDecoration: "none" }}
          >
            <img src={AllImages.whiteLogo} />
          </Link>

          <div className="header_right_flexContainer">
            {/* <div className="header_help_container">
              <div className="header_helpText">Help</div>
              <div className="header_help_dropIcon">
                <img src={AllImages.headerDropIcon} />
              </div>
            </div> */}
            <div
              onClick={handleComingSoonScreen}
              className="header_subscribeBtn"
            >
              Subscription
            </div>
            <div className="header_supportBtn" onClick={handleSupportScreen}>
              Support
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.mycarrus&hl=en&gl=US"
              target="_blank"
              className="header_googleBtn"
            >
              <img src={AllImages.googleHeadButton} />
            </a>
            <a
              href="https://apps.apple.com/us/app/my-carrus/id6473939397"
              target="_blank"
              className="header_appBtn"
            >
              <img src={AllImages.appStoreHeadButton} />
            </a>
          </div>
          <div className="header_bottom_border"></div>
        </div>
      )}
    </div>
  );
};

export default Header;
