import React, { useState, useEffect } from "react";
import "./settings.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import PasswordTag from "../../../containers/passwordTag/PasswordTag";
import InviteUserModal from "../../../containers/inviteUserModal/InviteUserModal";
import { useNavigate } from "react-router";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "jquery";

const Settings = () => {
  const [tab, setTab] = useState(0);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const navigate = useNavigate();
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo({
      ...passwordInfo,
      [name]: value,
    });
  };

  const handleTabZero = () => {
    setTab(0);
  };

  const handleTabOne = () => {
    setTab(1);
  };

  const handleOpenInviteUser = () => {
    setShowInviteUser(true);
  };

  const handleCloseInviteUser = () => {
    setShowInviteUser(false);
  };

  const handleManageAllAdmin = () => {
    navigate("/admin/settings/view_admin");
  };

  const loginF = async () => {
    showLoader();
    const data = {
      current_password: passwordInfo.currentPassword,
      new_password: passwordInfo.confirmNewPassword,
    };

    try {
      const res = await axiosCalls("api/v1/user/update_password", "POST", data);
      if (res) {
        hideLoader();
        if (res.er) {
          toast.error(res?.er?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(res.er);
          return;
        }
        // console.log(">>>>>>>>RES", res?.data);
        // localStorage.setItem("token", res?.data?.token?.token);
        // localStorage.setItem("res", JSON.stringify(res?.data));
        // localStorage.setItem("role", res?.data?.userDto?.role);

        toast.success("Password Changed successfuly", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          window.location.href = "/admin/overview";
        }, 2000);
        return;
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AdminBodyMargin>
      <div className="registered_users_heading">Settings</div>
      <div className="registered_user_nav_body">
        <div className="registered_user_nav_absolute">
          <div className=" registered_user_nav_absoluteFlex_left">
            <div
              onClick={handleTabZero}
              className={
                tab === 0
                  ? "registered_user_act_text_active"
                  : "registered_user_act_text"
              }
            >
              Password
            </div>
            <div
              onClick={handleTabOne}
              className={
                tab === 1
                  ? "registered_user_act_text_active"
                  : "registered_user_act_text"
              }
            >
              Notification
            </div>
          </div>
        </div>
      </div>

      {tab === 0 && (
        <div className="setting_tabone_body">
          <div className="setting_tabone_left">
            <div className="setting_tabone_headerText">Password Settings</div>

            <div className="settings_tabone_headerInfo">
              Please enter your current security password to change your
              password
            </div>

            <div className="settings_tabone_passwordRules">
              <div className="settings_tabone_passwordRules_head">
                Rules for passwords
              </div>
              <div className="settings_tabone_passwordRules_SubText">
                To create a new password, you have to meet all of the following
                requirement:
              </div>
              <div className="settings_tabone_passwordRequ">
                <div className="settings_tabone_passwordRequ_left">
                  <li className="settings_tabone_passwordRequ_li">
                    Minimum 8 character{" "}
                  </li>
                  <li className="settings_tabone_passwordRequ_li">
                    At least one special character
                  </li>
                </div>
                <div className="settings_tabone_passwordRequ_right">
                  <li className="settings_tabone_passwordRequ_li">
                    Minimum 8 character{" "}
                  </li>
                  <li className="settings_tabone_passwordRequ_li">
                    At least one special character
                  </li>
                </div>
              </div>
            </div>
            <PasswordTag
              passPlaceHolder="********"
              passLabel="Current Password *"
              passValue={passwordInfo.currentPassword}
              passName="currentPassword"
              passOnchange={handleChange}
              required={true}
            />
            <PasswordTag
              passPlaceHolder="********"
              passLabel="New Password *"
              passValue={passwordInfo.newPassword}
              passName="newPassword"
              passOnchange={handleChange}
              required={true}
            />
            <PasswordTag
              passPlaceHolder="********"
              passLabel="Confirm New Password *"
              passValue={passwordInfo.confirmNewPassword}
              passName="confirmNewPassword"
              passOnchange={handleChange}
              required={true}
            />

            <div onClick={loginF} className="settings_tabone_passwordRequ_btn">
              Update Password
            </div>
          </div>
          <div className="setting_tabone_right">
            <div
              onClick={handleOpenInviteUser}
              className="setting_tabone_invite_admin"
            >
              Invite Admin
            </div>
            <div
              onClick={handleManageAllAdmin}
              className="setting_tabone_invite_admin"
            >
              Manage All Admin
            </div>
          </div>
        </div>
      )}

      {tab === 1 && <div>Loading...</div>}

      {showInviteUser && (
        <InviteUserModal handleCloseModal={handleCloseInviteUser} />
      )}
    </AdminBodyMargin>
  );
};

export default Settings;
