import React from "react";
import "./newPasswordCheck.css";
import NewModal from "../../../containers/newModal/NewModal";
import AllImages from "../../../utils/dataList/AllImages";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";

const NewPasswordCheck = ({ closeNewModal }) => {
  return (
    <NewModal closeNewModal={closeNewModal}>
      <div className="newPassword_check___">
        <img src={AllImages.checked} />
      </div>

      <div className="newPAssword_check_te">Password Reset</div>

      <div className="newPAsswordText__sub">
        Your password has been successfully reset. Click below to log in
        magically
      </div>

      <DefaultLoginBtn handleBtnClick={closeNewModal} btnText="Continue" />
    </NewModal>
  );
};

export default NewPasswordCheck;
