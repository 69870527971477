import React, { useState, useEffect } from "react";
import "./viewAllAdmin.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import TableComp from "../../../containers/table/TableComp";

const lookup = { true: "Active", false: "Deactivated" };

const ViewAllAdmin = () => {
  const [userData, setUserData] = useState({});
  const data = [
    // {
    //   userName: "Daniel Regal",
    //   emailAddress: "daniel.re@gmail.com",
    //   phoneNumber: "1987989888",
    //   addedDate: "5th, Oct, 2023",
    //   // status: "Active",
    //   status: true,
    // },
    // {
    //   userName: "Daniel Regal",
    //   emailAddress: "daniel.re@gmail.com",
    //   phoneNumber: "1987989888",
    //   addedDate: "5th, Oct, 2023",
    //   // status: "Active",
    //   status: true,
    // },
    // {
    //   userName: "Daniel Regal",
    //   emailAddress: "daniel.re@gmail.com",
    //   phoneNumber: "1987989888",
    //   addedDate: "5th, Oct, 2023",
    //   // status: "Active",
    //   status: true,
    // },
    // {
    //   userName: "Daniel Regal",
    //   emailAddress: "daniel.re@gmail.com",
    //   phoneNumber: "1987989888",
    //   addedDate: "5th, Oct, 2023",
    //   // status: "Active",
    //   status: true,
    // },
    // {
    //   userName: "Daniel Regal",
    //   emailAddress: "daniel.re@gmail.com",
    //   phoneNumber: "1987989888",
    //   addedDate: "5th, Oct, 2023",
    //   // status: "Active",
    //   status: true,
    // },
  ];

  const getObjectFromLocalStorage = () => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem("res");

    // Parse the JSON string to an object
    const storedObject = JSON.parse(jsonString);
    setUserData(storedObject);
    console.log(userData, "User Data");
    // Use the storedObject in your component
  };

  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);

  const handleRowClick = (event, rowData) => {
    // Do something with the clicked row data, e.g., navigate to a detail page
    console.log("Clicked row data:", rowData);
  };

  const columns = [
    { title: "User name", field: "userName" },
    { title: "Email address", field: "emailAddress" },
    { title: "Added Date", field: "addedDate" },
    {
      title: "Status",
      field: "status",
      lookup,
      render: (rowData) => (
        <span style={{ color: rowData.status ? "green" : "red" }}>
          {lookup[rowData.status]}
        </span>
      ),
    },
  ];

  // Get the first letter of the first name
  const firstInitial = userData?.userDto?.firstName
    ? userData?.userDto?.firstName?.charAt(0).toUpperCase()
    : "";

  // Get the first letter of the last name
  const lastInitial = userData?.userDto?.lastName
    ? userData?.userDto?.lastName?.charAt(0).toUpperCase()
    : "";

  // Combine the first initials
  const initials = firstInitial + lastInitial;

  return (
    <AdminBodyMargin>
      <div className="view_all_admin_body_header">Admin Profile</div>

      <div className="view_all_admin_body_flex_head_two">
        <div className="view_all_admin_body_flex_first">
          <div className="view_all_admin_body_flex_first_id">{initials}</div>
          <div className="view_all_admin_body_flex_first_info">
            <div className="view_all_admin_body_flex_first_info_name">
              {userData?.userDto?.firstName} {userData?.userDto?.lastName} -
              <span>
                {" "}
                {userData?.userDto?.role === "SUPERADMIN"
                  ? "Super Admin"
                  : "Admin"}
              </span>
            </div>
            <div className="view_all_admin_body_flex_first_info_email">
              {userData?.userDto?.email}
            </div>
          </div>
        </div>

        {/* <div className="view_all_admin_body_flex_second_btn">Edit</div> */}
      </div>

      <div className="view_all_admin_body_flex_other_admin_list">
        Other Admin Personnel
      </div>

      <TableComp
        handleRowClick={handleRowClick}
        tableColumn={columns}
        tableData={data}
      />
    </AdminBodyMargin>
  );
};

export default ViewAllAdmin;
