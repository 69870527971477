import React from "react";
import "./home.css";
import HomeSideMargin from "../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../containers/header/Header";
import HomeFirstSec from "../../containers/homefirstSec/HomeFirstSec";
import HomeSecondSec from "../../containers/homeSecondSec/HomeSecondSec";
import HomeThirdSec from "../../containers/homeThirdSec/HomeThirdSec";
import HomeFourthSec from "../../containers/homeFourthSec/HomeFourthSec";
import Footer from "../../containers/footer/Footer";

const Home = () => {
  return (
    <>
      <HomeSideMargin>
        <Header />
        <HomeFirstSec />
      </HomeSideMargin>
      <HomeSecondSec />
      <HomeThirdSec />
      <HomeFourthSec />
      <Footer />
    </>
  );
};

export default Home;
