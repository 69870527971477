import React, { useState } from "react";
import "./inviteAdminSetup.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import TextInput from "../../../containers/textInput/TextInput";
import PasswordTag from "../../../containers/passwordTag/PasswordTag";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";

const InviteAdminSetup = () => {
  const [drawInfo, setDrawInfo] = useState({
    password: "",
    confirmPassword: "",
    token: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrawInfo({
      ...drawInfo,
      [name]: value,
    });
  };

  const { token } = useParams();

  //   const handleGetToken = async () => {
  //     showLoader();

  //     console.log(drawInfo, "Draw Info");

  //     try {
  //       const res = await axiosCalls(
  //         "api/v1/user/invite/admin",
  //         "GET"
  //       );
  //       if (res) {
  //         hideLoader();
  //         if (res.er) {
  //           toast.error(res?.er?.data?.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "dark",
  //           });
  //           console.log(res.er);
  //           return;
  //         }
  //         console.log(">>>>>>>>RES", res?.data);
  //         // localStorage.setItem("token", res?.data?.token?.token);

  //         // window.location.href = "/admin/overview";
  //         toast.success("Login successful", "Welcome admin", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });

  //         return;
  //       }
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const handleSubmitPassword = async () => {
    showLoader();

    console.log(drawInfo, "Draw Info");

    const data = {
      password: drawInfo.password,
      confirmPassword: drawInfo.confirmPassword,
      token: token,
    };

    try {
      const res = await axiosCalls("api/v1/user/admin/signup", "POST", data);
      if (res) {
        hideLoader();
        if (res.er) {
          toast.error(res?.er?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(res.er);
          return;
        }
        console.log(">>>>>>>>RES", res?.data);
        // localStorage.setItem("token", res?.data?.token?.token);

        // window.location.href = "/admin/settings/view_admin";
        toast.success("Admin Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
      }
      console.log(res);

      setTimeout(() => {
        window.location.href = "/admin/settings/view_admin";
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AdminBodyMargin>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmitPassword();
        }}
        className="invite_admin_setup_body"
      >
        <div className="invite_admin_setup_body_text">
          Please fill your password below to complete invite process
        </div>
        <PasswordTag
          passLabel="Password"
          passValue={drawInfo.password}
          passName="password"
          passOnchange={handleChange}
          passPlaceHolder="Password"
          required={true}
        />

        <PasswordTag
          passLabel="Confirm Password"
          passValue={drawInfo.confirmPassword}
          passName="confirmPassword"
          passOnchange={handleChange}
          passPlaceHolder="Confirm Password"
          required={true}
        />
        <div style={{ marginTop: "30px" }}></div>
        <DefaultLoginBtn width="100%" btnText="Submit" />
      </form>
      <ToastContainer />
    </AdminBodyMargin>
  );
};

export default InviteAdminSetup;
