import React from "react";
import "./homeSideMarginTrans.css";

const HomeSideMarginTrans = ({ children }) => {
  return (
    <div className="home_sectionTransCont">
      <div className="home_sectionTrans_gen">{children}</div>
    </div>
  );
};

export default HomeSideMarginTrans;
