import React from "react";
import "./authScreenParent.css";
import AllImages from "../../utils/dataList/AllImages";

const AuthScreenParent = ({ children }) => {
  return (
    <div className="loginGenCont_">
      <div className="loginAdmin_logo">
        <img src={AllImages.myCarrusLogoAdmin} />
      </div>
      <div className="loginForm_fieldCont">
        <div className="loginForm_gradient___">{children}</div>
      </div>
    </div>
  );
};

export default AuthScreenParent;
