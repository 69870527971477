import React, { useState } from "react";
import "./login.css";
import TextInput from "../../../containers/textInput/TextInput";
import PasswordTag from "../../../containers/passwordTag/PasswordTag";
import AllImages from "../../../utils/dataList/AllImages";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";
import { useNavigate } from "react-router-dom";
import AuthScreenParent from "../../../containers/authScreenParent/AuthScreenParent";
import { axiosCalls } from "../../../_api";
import { NotificationManager } from "react-notifications";
import { hideLoader, showLoader } from "../../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleForgotpassNavigate = () => {
    navigate("/admin/forgot_password");
  };

  const handleLoginNavigate = () => {
    navigate("/admin/overview");
  };

  const loginF = async () => {
    showLoader();

    try {
      const res = await axiosCalls("api/v1/user/login", "POST", auth);
      if (res) {
        hideLoader();
        if (res.er) {
          toast.error(res?.er?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(res.er);
          return;
        }
        console.log(">>>>>>>>RES", res?.data);
        localStorage.setItem("token", res?.data?.token?.token);
        localStorage.setItem("res", JSON.stringify(res?.data));
        localStorage.setItem("role", res?.data?.userDto?.role);

        window.location.href = "/admin/overview";
        toast.success("Login successful", "Welcome admin", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
        // if (res?.data?.role == "superadmin") {
        //   console.log(res.data);
        //   localStorage.setItem("token", res?.data?.token);
        //   window.location.href = `/dashboard`;
        //   NotificationManager.success("Login successful", "Welcome admin");
        //   return;
        // }
        // NotificationManager.error("Permission denied", "Error");
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }

    console.log(auth);
    // history.push(`/dashboard`);
    // NotificationManager.success("Login successfull", "Welcom admin");
  };

  const handleEmailInput = (e) => {
    setAuth({ ...auth, email: e.target.value });
  };

  const handlePasswordInput = (e) => {
    setAuth({ ...auth, password: e.target.value });
  };
  return (
    <AuthScreenParent>
      <div className="loginForm_loginText">Log in</div>
      <div className="loginForm_InfoLo">
        Kindly provide the following details to access your account
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          loginF();
        }}
      >
        <div className="logintextInput_fa">
          <TextInput
            inputLabel="Email Address *"
            inputPlaceholder="John.doe@example.co"
            inputType="email"
            inputOnchange={handleEmailInput}
            inputValue={auth?.email}
          />
        </div>
        <div className="lognpassInput_fa">
          <PasswordTag
            passOnchange={handlePasswordInput}
            passValue={auth?.password}
            passPlaceHolder="Enter password"
            passLabel="Password *"
          />
        </div>
        <div
          onClick={handleForgotpassNavigate}
          className="loginForgotPassword__"
        >
          Forgot Password?{" "}
        </div>
        <DefaultLoginBtn
          type="submit"
          handleBtnClick={loginF}
          btnText="Login"
          width="100%"
        />
      </form>

      <ToastContainer />
    </AuthScreenParent>
  );
};

export default Login;
