import whiteLogo from "../../assets/images/whiteLogo.svg";
import googleHeadButton from "../../assets/images/googleHeadButton.svg";
import appStoreHeadButton from "../../assets/images/appStoreHeadButton.svg";

import headerDropIcon from "../../assets/images/headerDropIcon.svg";
import carrusFirstSecImg from "../../assets/images/carrusFirstSecImg.svg";

import googleBtnSec from "../../assets/images/googleBtnSec.svg";
import appleBtnSec from "../../assets/images/appleBtnSec.svg";
import crysler from "../../assets/images/crysler.svg";
import lincoln from "../../assets/images/lincoln.svg";
import kia from "../../assets/images/kia.svg";
import honda from "../../assets/images/honda.svg";
import ford from "../../assets/images/ford.svg";
import dodge from "../../assets/images/dodge.svg";
import chevrolet from "../../assets/images/chevrolet.svg";
import benz from "../../assets/images/benz.svg";
import toyota from "../../assets/images/toyota.svg";
import compImgOne from "../../assets/images/compImgOne.svg";
import thirdSecImg from "../../assets/images/thirdSecImg.svg";
import fourthImg from "../../assets/images/fourthImg.svg";
import fourthGooglebtn from "../../assets/images/fourthGooglebtn.svg";

import fourthAppbtn from "../../assets/images/fourthAppbtn.svg";

import vehicleTwo from "../../assets/images/vehicleTwo.svg";
import fairTrans from "../../assets/images/fairTrans.svg";
import subPack from "../../assets/images/subPack.svg";
import closeeye from "../../assets/images/closeeye.svg";
import myCarrusLogoAdmin from "../../assets/images/myCarrusLogoAdmin.svg";
import key from "../../assets/images/key.svg";
import loginBack from "../../assets/images/loginBack.svg";
import checkEmail from "../../assets/images/checkEmail.svg";
import checked from "../../assets/images/checked.svg";
import message_icon from "../../assets/images/message_icon.svg";
import overview_icon from "../../assets/images/overview_icon.svg";
import users_icon from "../../assets/images/users_icon.svg";
import drawings_icon from "../../assets/images/drawings_icon.svg";
import notifications_icon from "../../assets/images/notification_icon.svg";
import subscriptions_icon from "../../assets/images/subscriptions_icon.svg";
import settings_icon from "../../assets/images/settings_icon.svg";
import logout_icon from "../../assets/images/logout_icon.svg";
import partners_icon from "../../assets/images/partners_icon.svg";
import bell_icon from "../../assets/images/bell_icon.svg";
import admin_logo from "../../assets/images/admin_logo.svg";
import download_icon from "../../assets/images/download_icon.svg";
import searchAdmin from "../../assets/images/searchAdmin.svg";
import mercedes_img from "../../assets/images/mercedes_img.svg";
import mercedes_logo from "../../assets/images/mercedes_logo.svg";
import imageIcon from "../../assets/images/imageIcon.svg";
import image_one from "../../assets/images/image_one.png";
import image_two from "../../assets/images/image_two.png";
import image_three from "../../assets/images/image_three.png";
import image_four from "../../assets/images/image_four.png";
import back_icon from "../../assets/images/back_icon.svg";
import step_one from "../../assets/images/step_1.png";
import step_two from "../../assets/images/step_2.png";
import step_three from "../../assets/images/step_3.png";

export default {
  whiteLogo,
  googleHeadButton,
  appStoreHeadButton,
  headerDropIcon,
  carrusFirstSecImg,
  googleBtnSec,
  appleBtnSec,
  crysler,
  lincoln,
  kia,
  honda,
  ford,
  dodge,
  chevrolet,
  benz,
  toyota,
  compImgOne,
  thirdSecImg,
  fourthImg,
  fourthGooglebtn,
  fourthAppbtn,
  vehicleTwo,
  fairTrans,
  subPack,
  closeeye,
  myCarrusLogoAdmin,
  key,
  loginBack,
  checkEmail,
  checked,
  message_icon,
  overview_icon,
  users_icon,
  drawings_icon,
  partners_icon,
  notifications_icon,
  subscriptions_icon,
  settings_icon,
  logout_icon,
  bell_icon,
  admin_logo,
  download_icon,
  searchAdmin,
  mercedes_img,
  mercedes_logo,
  imageIcon,
  image_one,
  image_two,
  image_three,
  image_four,
  back_icon,
  step_one,
  step_two,
  step_three,
};
