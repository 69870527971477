import React, { useState, useEffect } from "react";
import "./drawings.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import AllImages from "../../../utils/dataList/AllImages";
import DrawingsCard from "../../../containers/drawingsCard/DrawingsCard";
import PastDrawingCard from "../../../containers/pastDrawingCard/PastDrawingCard";
import CreateDrawingForm from "../../../containers/createDrawingForm/CreateDrawingForm";
import ActiveDrawingInfo from "../../../containers/activeDrawingInfo/ActiveDrawingInfo";
import {
  activeDrawingCardInfo,
  pastDrawingCardInfo,
} from "../../../utils/dataList/DataList";
import EachPastDrawingScreen from "../../../containers/eachPastDrawingScreen/EachPastDrawingScreen";
import EachActiveDrawingScreen from "../../../containers/eachActiveDrawingScreen/EachActiveDrawingScreen";
import { hideLoader, showLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";

const Drawings = () => {
  const [tab, setTab] = useState(0);
  const [openDrawingForm, setOpenDrawingForm] = useState(false);
  const [openActiveDrawing, setOpenActiveDrawing] = useState(false);
  const [openEachPastDrawing, setOpenEachPastDrawing] = useState(false);
  const [openEachActiveDrawing, setOpenEachActiveDrawing] = useState(false);

  const [eachPastDrawingInfo, setEachPastDrawingInfo] = useState({});
  const [eachActiveDrawingInfo, setEachActiveDrawingInfo] = useState({});
  const [eachIndex, setEachIndex] = useState();
  const [user, setUser] = useState();
  const [loading, userLoading] = useState(true);
  const [drawData, setDrawData] = useState([]);
  const [noEntry, setNoEntry] = useState("");

  useEffect(() => {
    getActiveDrawings();
  }, []);

  const handleTabZero = () => {
    setTab(0);
  };

  const handleTabOne = () => {
    setTab(1);
  };

  const handleOpenDrawingForm = () => {
    setOpenDrawingForm(true);
  };

  const handleCloseDrawingForm = () => {
    setOpenDrawingForm(false);
  };

  const handleActiveDrawingOpen = () => {
    setOpenActiveDrawing(true);
  };

  const handleActiveDrawingClose = () => {
    setOpenActiveDrawing(false);
  };

  const handleOpenEachPastDrawing = (eachInfo) => {
    setOpenEachPastDrawing(true);
    setEachPastDrawingInfo(eachInfo);
  };

  const handleCloseEachPastDrawing = () => {
    setOpenEachPastDrawing(false);
  };

  const handleCloseEachActiveDrawing = () => {
    setOpenEachActiveDrawing(false);
  };

  const handleOpenEachActiveDrawing = (eachInfo, index, noEntryD) => {
    setOpenEachActiveDrawing(true);
    setEachActiveDrawingInfo(eachInfo);
    setNoEntry(noEntryD);
    console.log(eachActiveDrawingInfo, "each");
    setEachIndex(index);
    console.log(index, "kkkkkk");
    // console.log(eachIndex, "oooooo");
  };

  const getActiveDrawings = async () => {
    showLoader();

    const res = await axiosCalls("api/v1/entry", "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        return;
      }

      hideLoader();
      console.log(res, "ffff");

      const resData = res?.data?.content;
      // setUser(resData);

      setDrawData(resData);

      console.log(">>>>>>>>>>Res", resData?.content);
    }
  };

  return (
    <AdminBodyMargin>
      <div className="drawings_body_header">Drawings</div>

      <div className="drawings_user_nav_body">
        <div className="drawings_user_nav_absolute">
          <div className=" drawings_user_nav_absoluteFlex_left">
            <div
              onClick={handleTabZero}
              className={
                tab === 0
                  ? "drawings_user_act_text_active"
                  : "drawings_user_act_text"
              }
            >
              Active Drawing ({drawData?.length})
            </div>
            <div
              onClick={handleTabOne}
              className={
                tab === 1
                  ? "drawings_user_act_text_active"
                  : "drawings_user_act_text"
              }
            >
              Past Drawing ({pastDrawingCardInfo?.length})
            </div>
          </div>

          <div
            onClick={handleOpenDrawingForm}
            className="drawings_user_nav_absoluteFlex_right"
          >
            Create Drawings
          </div>
        </div>
      </div>

      {tab === 0 &&
        (drawData?.length < 1 ? (
          <div className="no_active_drawing_yet"> No Active Drawings Yet </div>
        ) : (
          <div className="drawings_user_nav_flex">
            {drawData?.map((activeInfo, index) => (
              <DrawingsCard
                car_logo={activeInfo?.carEntryDraw?.brand_logo}
                car_image={activeInfo?.carEntryDraw?.display_pic}
                draw_time={activeInfo?.carEntryDraw?.start_date}
                current_entry={activeInfo?.no_participants}
                end_date={activeInfo?.carEntryDraw?.end_date}
                handleCloseEachActiveDrawing={handleCloseEachActiveDrawing}
                handleOpenEachActiveDrawing={() => {
                  handleOpenEachActiveDrawing(
                    activeInfo?.carEntryDraw,
                    activeInfo?.carEntryDraw?.id,
                    activeInfo?.no_participants
                  );
                }}
                eachIndex={activeInfo?.carEntryDraw?.id}
              />
            ))}
          </div>
        ))}

      {tab === 1 && (
        <div className="drawings_user_nav_flex">
          {pastDrawingCardInfo.map((pastInfo, index) => (
            <PastDrawingCard
              car_image={pastInfo.car_image}
              car_logo={pastInfo.car_logo}
              winner_id={pastInfo.winner_id}
              total_entries={pastInfo.total_entries}
              drawing_date={pastInfo.drawing_date}
              handleOpenEachPastDrawing={() => {
                handleOpenEachPastDrawing(pastInfo);
              }}
              handleCloseEachPastDrawing={handleCloseEachPastDrawing}
            />
          ))}
        </div>
      )}

      {openDrawingForm && (
        <CreateDrawingForm handleCloseDrawingForm={handleCloseDrawingForm} />
      )}

      {openEachPastDrawing && (
        <EachPastDrawingScreen
          handleCloseEachPastDrawing={handleCloseEachPastDrawing}
          eachPastDrawingInfo={eachPastDrawingInfo}
        />
      )}

      {openEachActiveDrawing && (
        <EachActiveDrawingScreen
          handleCloseEachActiveDrawing={handleCloseEachActiveDrawing}
          eachActiveDrawingInfo={eachActiveDrawingInfo}
          eachIndex={eachIndex}
          noEntry={noEntry}
          setOpenEachActiveDrawing={setOpenEachActiveDrawing}
        />
      )}
    </AdminBodyMargin>
  );
};

export default Drawings;
