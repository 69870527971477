import React from "react";
import "./overviewCard.css";

const OverviewCard = ({ total, cardText }) => {
  return (
    <div className="overview_card_body">
      <div className="overview_card_child">
        <div className="overview_card_total">{total}</div>
        <div className="overview_card_text">{cardText}</div>
      </div>
    </div>
  );
};

export default OverviewCard;
