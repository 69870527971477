import React from "react";
import "./textInput.css";

const TextInput = ({
  inputLabel,
  inputPlaceholder,
  inputValue,
  inputName,
  inputType,
  inputOnchange,
  width,
  required,
}) => {
  return (
    <div className="textInput_cont" style={{ width }}>
      <div className="textInput_label">{inputLabel}</div>
      <div className="textInput__">
        <input
          type={inputType}
          placeholder={inputPlaceholder}
          value={inputValue}
          name={inputName}
          onChange={inputOnchange}
          required
        />
      </div>
    </div>
  );
};

export default TextInput;
