import React, { useState } from "react";
import "./inviteUserModal.css";
import InfoModal from "../infoModal/InfoModal";
import TextInput from "../textInput/TextInput";
import { axiosCalls } from "../../_api";
import { hideLoader, showLoader } from "../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InviteUserModal = ({ handleCloseModal }) => {
  const [drawInfo, setDrawInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [resendLink, setResendLink] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrawInfo({
      ...drawInfo,
      [name]: value,
    });
  };

  const handleResendChange = (e) => {
    const { name, value } = e.target;
    setResendLink({
      ...resendLink,
      [name]: value,
    });
  };

  const handleInviteAdmin = async () => {
    showLoader();

    console.log(drawInfo, "Draw Info");

    try {
      const res = await axiosCalls(
        "api/v1/user/invite/admin",
        "POST",
        drawInfo
      );
      if (res) {
        hideLoader();
        if (res.er) {
          toast.error(res?.er?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(res.er);
          return;
        }
        console.log(">>>>>>>>RES", res?.data);
        // localStorage.setItem("token", res?.data?.token?.token);

        // window.location.href = "/admin/overview";
        toast.success("Login successful", "Welcome admin", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendAdmin = async () => {
    showLoader();

    console.log(resendLink, "Resend Email");

    try {
      const res = await axiosCalls(
        "api/v1/user/resend/admin/invite",
        "POST",
        resendLink
      );
      if (res) {
        hideLoader();
        if (res.er) {
          toast.error(res?.er?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(res.er);
          return;
        }
        console.log(">>>>>>>>RES", res?.data);
        // localStorage.setItem("token", res?.data?.token?.token);

        // window.location.href = "/admin/overview";
        toast.success("Resent successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <InfoModal handleCloseModal={handleCloseModal}>
      <div className="invite_single_user_header">
        <div className="invite_single_user_header_text">Invite an Admin</div>
        <div
          onClick={handleCloseModal}
          className="invite_single_user_header_btn"
        >
          X
        </div>
      </div>

      <div className="invite_single_user_header_btn_header">
        Invite an Admin
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleInviteAdmin();
        }}
      >
        <TextInput
          inputLabel="First Name *"
          inputName="firstName"
          inputOnchange={handleChange}
          inputPlaceholder="First Name"
          inputType="text"
          inputValue={drawInfo.firstName}
          required={true}
        />
        <TextInput
          inputLabel="Last Name *"
          inputName="lastName"
          inputOnchange={handleChange}
          inputPlaceholder="Last Name"
          inputType="text"
          inputValue={drawInfo.lastName}
          required={true}
        />
        <TextInput
          inputLabel="Email Address *"
          inputName="email"
          inputOnchange={handleChange}
          inputPlaceholder="email"
          inputType="email"
          inputValue={drawInfo.email}
          required={true}
        />
        <button className="invite_single_userSub_btn" type="submit">
          Invite an Admin
        </button>
      </form>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleResendAdmin();
        }}
      >
        <div className="invite_single_user_header_btn_header">
          {" "}
          Resend the verification Link after verification expired{" "}
        </div>
        <TextInput
          inputLabel="Email Address *"
          inputName="email"
          inputOnchange={handleResendChange}
          inputPlaceholder="email"
          inputType="email"
          inputValue={resendLink.email}
          required={true}
        />
        <button className="invite_single_userSub_btn" type="submit">
          Resend Link
        </button>
      </form>
    </InfoModal>
  );
};

export default InviteUserModal;
