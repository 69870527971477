import React, { useState, useEffect } from "react";
import "./eachActiveDrawingScreen.css";
import InfoModal from "../infoModal/InfoModal";
import AllImages from "../../utils/dataList/AllImages";
import PastDrawingCard from "../pastDrawingCard/PastDrawingCard";
import ActiveDrawingEach from "../activeDrawingEach/ActiveDrawingEach";
import { axiosCalls, axiosFormData } from "../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoader, showLoader } from "../../loader";

const EachActiveDrawingScreen = ({
  handleCloseEachActiveDrawing,
  eachActiveDrawingInfo,
  eachIndex,
  noEntry,
  setOpenEachActiveDrawing,
}) => {
  const {
    brand_logo,
    display_pic,
    manufacture_year,
    color,
    engine_size,
    registered_city,
    car_make,
    car_model,
    condition,
    mileage,
    start_date,
    end_date,
    other_photo,
    description,
    start_time,
    current_entry,
    entry_ends,
    draw_time,
    images,
    no_participants,
  } = eachActiveDrawingInfo;

  const [additionalImages, setAdditionalImages] = useState([]);
  const [displayAdditionalImages, setDisplayAdditionalImages] = useState([]);
  const [deleteDrawId, setDeleteDrawId] = useState([eachIndex]);

  useEffect(() => {
    // displayAdditionalImages(images);

    console.log(eachIndex, "lkjdjsdkks");
  }, []);

  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     // Use FileReader to read the selected image as a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDrawInfo({
  //         ...drawInfo,
  //         carLogo: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(selectedImage);
  //   }
  // };

  // const handleLogoChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     setDrawInfo({
  //       ...drawInfo,
  //       carLogo: selectedImage,
  //     });
  //   }
  // };
  const checkIndexNumber = () => {
    console.log(eachIndex);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    // Check if any files are selected
    if (files.length > 0) {
      const newImages = new FormData();

      const newImagesDisplay = Array.from(files);
      files.forEach((img) => {
        newImages.append("images", img);
      });
      //   Update the state with the new images
      setDisplayAdditionalImages((prevImages) => {
        const updatedImages = [...prevImages, ...newImagesDisplay];
        console.log(updatedImages, "addddddddddd");
        return updatedImages;
      });

      setAdditionalImages(newImages);

      //   return newImages;
    }
  };

  const handleAddMorePhotos = async (e) => {
    showLoader();
    const res = await axiosFormData(
      `api/v1/entry/upload-images/${eachIndex}`,
      "POST",
      additionalImages
    );

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.message);
        toast.error("Error, Please Try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("Updated Succesfully", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      handleCloseEachActiveDrawing();

      setTimeout(() => {
        window.location.href = "/admin/drawings";
      }, 2000);
    }
  };

  const handleDeleteDraw = async (e) => {
    //  const data = {
    //    password: drawInfo.password,
    //    confirmPassword: drawInfo.confirmPassword,
    //    token: token,
    //  };

    const data = {
      ids: deleteDrawId,
    };
    showLoader();
    const res = await axiosCalls(`api/v1/entry/delete`, "DELETE", data);

    if (res) {
      hideLoader();
      console.log(res?.data, "data");
      if (res?.er) {
        console.log(res?.data?.message, "data message");
        toast.error("Error, Please Try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("DELETED Succesfully", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      handleCloseEachActiveDrawing();

      //    setTimeout(() => {
      //      window.location.href = "/admin/drawings";
      //    }, 2000);
    }
  };

  const handleStopDraw = async (e) => {
    const data = {
      status: "SUSPENDED",
    };

    console.log(data, "6636633");

    showLoader();
    const res = await axiosFormData(
      `api/v1/entry/update/${eachIndex}`,
      "PUT",
      data
    );

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.message);
        toast.error("Error, Please Try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("Draw Stopped successfully", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      //   setTimeout(() => {
      //     window.location.href = "/admin/drawings";
      //   }, 2000);
    }
  };

  return (
    <InfoModal
      background="#000"
      handleCloseModal={handleCloseEachActiveDrawing}
      width="90%"
    >
      <div className="each_active_draw_heading_flex">
        <div className="each_active_draw_heading_flex_text">Drawing</div>
        <div className="each_active_draw_heading_btnBody">
          <div
            onClick={handleStopDraw}
            className="each_active_draw_heading_stop_btn"
          >
            Stop Drawing
          </div>
          <div
            onClick={handleDeleteDraw}
            className="each_active_draw_heading_del_btn"
          >
            Delete Drawing
          </div>
        </div>
      </div>

      <div className="each_active_draw_car_make_modelBackBody">
        <div
          onClick={handleCloseEachActiveDrawing}
          className="each_active_draw_car_make_modelBackIcon"
        >
          <img src={AllImages.back_icon} />
        </div>
        <div className="each_active_draw_car_make_model_name">
          {/* {manufacture_year} {car_make} {car_model} */}
        </div>
      </div>
      <div className="each_active_draw_bottom_line"></div>

      <div className="each_active_draw_left_rigthBody__">
        <ActiveDrawingEach
          car_image={display_pic}
          car_logo={brand_logo}
          draw_time={start_date}
          current_entry={noEntry}
          end_date={end_date}
          eachIndex={eachIndex}
        />

        <div className="each_active_draw_rightBody">
          <div className="each_active_draw_right_makeText">
            {manufacture_year} {car_make} {car_model}
          </div>

          <div className="each_active_draw_right_make_info">{description}</div>
          <div className="each_active_drawing_detailsSec_b">
            <div className="each_active_drawing_details_mainColumn">
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {car_make}
                </div>
                <div className="each_active_drawing_details_head">MAKE</div>
              </div>
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {manufacture_year}{" "}
                </div>
                <div>YEAR OF MANUFACTURE</div>
              </div>
            </div>
            <div className="each_active_drawing_details_mainColumn">
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {car_model}
                </div>
                <div className="each_active_drawing_details_head">MODEL</div>
              </div>
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">{color}</div>
                <div>COLOUR </div>
              </div>
            </div>
            <div className="each_active_drawing_details_mainColumn">
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {condition}
                </div>
                <div className="each_active_drawing_details_head">
                  CONDITION
                </div>
              </div>
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {engine_size}
                </div>
                <div>ENGINE SIZE </div>
              </div>
            </div>
            <div className="each_active_drawing_details_mainColumn">
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {mileage}
                </div>
                <div className="each_active_drawing_details_head">MILEAGE</div>
              </div>
              <div className="each_active_drawing_details_column">
                <div className="each_active_drawing_details_text">
                  {registered_city}
                </div>
                <div>REGISTERED CITY </div>
              </div>
            </div>
          </div>

          <div className="each_active_drawing_detailsSec_bottom_border"></div>
          <div className="each_active_drawing_details_eachImage_head">
            More Photos
          </div>
          <div className="each_active_drawing_details_body_flex">
            {images?.map((file, index) => (
              <div className="each_active_drawing_details_eacImage" key={index}>
                <img src={file?.url} alt={`Image ${index}`} />
              </div>
            ))}
            {displayAdditionalImages?.map((file, index) => (
              <div className="each_active_drawing_details_eacImage" key={index}>
                {file instanceof Blob && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Image ${index}`}
                    // style={{ width: "100%", objectFit: "cover" }}
                  />
                )}
              </div>
            ))}

            <div className="each_active_add_photo">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                multiple // Allow selecting multiple files
              />
              <div className="each_active_add_photo_plus">+</div>
              <div className="each_active_add_photo_text">Add More Photo</div>
            </div>
          </div>

          <div
            onClick={handleAddMorePhotos}
            className="each_active_photos_update"
          >
            Update Photos
          </div>
        </div>
      </div>
    </InfoModal>
  );
};

export default EachActiveDrawingScreen;
