import React from "react";
import "./pastDrawingCard.css";
import AllImages from "../../utils/dataList/AllImages";

const PastDrawingCard = ({
  car_logo,
  car_image,
  winner_id,
  total_entries,
  drawing_date,
  handleOpenEachPastDrawing,
  handleCloseEachPastDrawing,
}) => {
  return (
    <div
      onClick={handleOpenEachPastDrawing}
      className="post_drawings_card_body"
    >
      <div className="post_drawings_card_body_child">
        <div className="post_drawings_card_car_logo">
          <img src={car_logo} />
        </div>

        <div className="post_drawings_card_car_img">
          <img src={car_image} />
        </div>

        {/* <div className="post_drawings_card_time_body">
          <div className="post_drawings_card_time_card">
            <div className="post_drawings_card_time_hour">64</div>
            <div className="post_drawings_card_time_hourText">Hour</div>
          </div>
          <div className="post_drawings_card_time_columnText">:</div>
          <div className="post_drawings_card_time_card">
            <div className="post_drawings_card_time_hour">15</div>
            <div className="post_drawings_card_time_hourText">Mins</div>
          </div>
          <div className="post_drawings_card_time_columnText">:</div>
          <div className="post_drawings_card_time_card">
            <div className="post_drawings_card_time_hour">23</div>
            <div className="post_drawings_card_time_hourText">Secs</div>
          </div>
        </div> */}

        <div className="past_drawing_winner_body">
          <div className="post_drawings_winner_text">WINNER</div>
          <div className="post_drawings_winner_id">USER:{winner_id}</div>
        </div>

        <div className="post_drawings_sub_card_body">
          <div className="post_drawings_sub_card_current">
            <div className="post_drawings_sub_card_entry">
              Number of total entries:
            </div>
            <div className="post_drawings_sub_card_users">{total_entries}</div>
          </div>
          <div className="post_drawings_sub_card_current">
            <div className="post_drawings_sub_card_entry">Drawing Date:</div>
            <div className="post_drawings_sub_card_users">{drawing_date}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastDrawingCard;
