import React from "react";
import Home from "./screens/home/Home";
import Login from "./screens/auth/login/Login";
import ForgotPassword from "./screens/auth/forgotPassword/ForgotPassword";
import Admin from "./screens/admin/Admin";
import NewPassword from "./screens/auth/newPassword/NewPassword";
import PrivacyPolicy from "./screens/home/privacyPolicy/PrivacyPolicy";
import { ProtectedLayout } from "./layout";
import Overview from "./screens/admin/overview/Overview";
import RegisteredUsers from "./screens/admin/registeredUsers/RegisteredUsers";
import Drawings from "./screens/admin/drawings/Drawings";
import Partners from "./screens/admin/partners/Partners";
import Message from "./screens/admin/message/Message";
import Notification from "./screens/admin/notification/Notification";
import Subscriptions from "./screens/admin/subscriptions/Subscriptions";
import Settings from "./screens/admin/settings/Settings";
import InviteAdminSetup from "./screens/admin/inviteAdminSetup/InviteAdminSetup";
import ViewAllAdmin from "./screens/admin/viewAllAdmin/ViewAllAdmin";
import TermsCondition from "./screens/home/terms_condition/TermsCondition";
import SupportScreen from "./screens/home/supportScreen/SupportScreen";
import DeleteMobileAccount from "./screens/home/deleteMobileAccount/DeleteMobileAccount";
import ComingSoon from "./screens/home/comingSoon/ComingSoon";

export const Homepage = () => {
  return <Home />;
};

export const PrivacyPolicypage = () => {
  return <PrivacyPolicy />;
};

export const TermsConditionPage = () => {
  return <TermsCondition />;
};

export const ComingSoonPage = () => {
  return <ComingSoon />;
};

export const Loginpage = () => {
  return <Login />;
};

export const ForgotPasswordpage = () => {
  return <ForgotPassword />;
};

export const NewPasswordpage = () => {
  return <NewPassword />;
};
export const Adminpage = () => {
  return <Admin />;
};

export const SupportScreenPage = () => {
  return <SupportScreen />;
};

export const DeleteMobileAccountPage = () => {
  return <DeleteMobileAccount />;
};
export const OverviewPage = (props) => {
  return (
    <ProtectedLayout>
      <Overview {...props} />
    </ProtectedLayout>
  );
};

export const RegisteredUsersPage = (props) => {
  return (
    <ProtectedLayout>
      <RegisteredUsers {...props} />
    </ProtectedLayout>
  );
};

export const DrawingsPage = (props) => {
  return (
    <ProtectedLayout>
      <Drawings {...props} />
    </ProtectedLayout>
  );
};

export const PartnersPage = (props) => {
  return (
    <ProtectedLayout>
      <Partners {...props} />
    </ProtectedLayout>
  );
};

export const InviteAdminSetupPage = (props) => {
  return (
    <ProtectedLayout>
      <InviteAdminSetup {...props} />
    </ProtectedLayout>
  );
};

export const ViewAllAdminPage = (props) => {
  return (
    <ProtectedLayout>
      <ViewAllAdmin {...props} />
    </ProtectedLayout>
  );
};

export const MessagePage = (props) => {
  return (
    <ProtectedLayout>
      <Message {...props} />
    </ProtectedLayout>
  );
};

export const SubscriptionsPage = (props) => {
  return (
    <ProtectedLayout>
      <Subscriptions {...props} />
    </ProtectedLayout>
  );
};

export const NotificationPage = (props) => {
  return (
    <ProtectedLayout>
      <Notification {...props} />
    </ProtectedLayout>
  );
};

export const SettingsPage = (props) => {
  return (
    <ProtectedLayout>
      <Settings {...props} />
    </ProtectedLayout>
  );
};
