import React from "react";
import "./homeSideMargin.css";

const HomeSideMargin = ({ children, sectionBackground }) => {
  return (
    <div className="home_sectionOneCont">
      <div className="home_sectionOne_gen" style={sectionBackground}>
        {children}
      </div>
    </div>
  );
};

export default HomeSideMargin;
