import React, { useState, useEffect } from "react";
import "./overview.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import AllImages from "../../../utils/dataList/AllImages";
import OverviewCard from "../../../containers/overviewCard/OverviewCard";
import MaterialTable, { Column } from "@material-table/core";
import { Input } from "@material-ui/core";
import TableComp from "../../../containers/table/TableComp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoModal from "../../../containers/infoModal/InfoModal";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import moment from "moment";

// const lookup = { true: "Active", false: "Deactivated" };

const Overview = () => {
  const [filterOption, setFilterOption] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [eachRowData, setEachRowData] = useState();
  const [userData, setUserData] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [userTableData, setUserTableData] = useState([]);

  const [currentDateTime, setCurrentDateTime] = useState("");
  const [drawData, setDrawData] = useState([]);
  const [activeUserCount, setActiveUserCount] = useState(0);

  useEffect(() => {
    const updateDateTime = () => {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedDateTime = new Date().toLocaleString("en-US", options);
      setCurrentDateTime(formattedDateTime);
    };

    // Call the function initially
    updateDateTime();

    // Update the date and time every minute
    const intervalId = setInterval(updateDateTime, 60000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = (dateString) => {
    const date = moment(dateString);
    return date.format("Do MMMM, YYYY");
  };

  const getUsersData = async () => {
    showLoader();

    const res = await axiosCalls("api/v1/user", "GET");

    if (res) {
      // userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        return;
      }

      hideLoader();
      console.log(res, "ffff");

      const resData = res?.data?.content;
      // setUser(resData);
      const jsonString = JSON.stringify(resData);
      localStorage.setItem("Overview", jsonString);
      setUserTableData(resData);
      // Calculate the active user count
      const activeUsers = resData.filter((user) => user.status === "ACTIVE");
      setActiveUserCount(activeUsers.length);

      console.log(">>>>>>>>>>Res", resData?.content);
    }
  };

  const getActiveDrawings = async () => {
    showLoader();

    const res = await axiosCalls("api/v1/entry", "GET");

    if (res) {
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        return;
      }

      hideLoader();
      console.log(res, "ffff");

      const resData = res?.data?.content;
      // setUser(resData);

      setDrawData(resData);

      console.log(">>>>>>>>>>Res", resData?.content);
    }
  };
  // useEffect(() => {
  //   toast.success("Login successful", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //   });
  // }, []);

  const navigate = useNavigate();

  const data = [
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "James Redd",
      emailAddress: "james.re@gmail.com",
      phoneNumber: "1890",
      registeredDate: "5th, Oct, 2022",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Foxx David",
      emailAddress: "david.fo@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Benjamin Thomas",
      emailAddress: "benj.re@gmail.com",
      phoneNumber: "1960",
      registeredDate: "5th, Oct, 2021",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
  ];

  const getObjectFromLocalStorage = () => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem("res");

    // Parse the JSON string to an object
    const storedObject = JSON.parse(jsonString);
    setUserData(storedObject);
    console.log(userData, "User Data");
    // Use the storedObject in your component
  };

  useEffect(() => {
    getObjectFromLocalStorage();
    getUsersData();
    getActiveDrawings();
  }, []);

  const columns = [
    {
      title: "User full name",
      field: "fullName",

      // align: "center",
      render: (rowData) => (
        // <RowDetails
        //   text={rowData.name}
        //   detailsText={rowData.email}
        //   rowImg={rowData.img}
        // />

        <div className="managed_users_table_role_body">
          <div className="manage_user_body_details_image">
            {rowData.firstName}
          </div>

          <div className="managed_users_table_role_text">
            {rowData.lastName}
          </div>
        </div>
      ),
    },
    { title: "Email address", field: "email" },
    { title: "Phone number", field: "phoneNo" },
    {
      title: "Registered Date",
      field: "createdAt",
      render: (rowData) => {
        const date = new Date(rowData.createdAt);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("en-GB", options);
      },
    },
    {
      title: "Status",
      field: "status",
      // lookup,
      render: (rowData) => (
        <span
          style={{
            color:
              rowData.status === "ACTIVE"
                ? "#14CD69"
                : rowData.status === "PENDING"
                ? "orange"
                : "red",
          }}
        >
          {rowData.status}
        </span>
      ),
    },
  ];

  const handleSeeMorebtn = () => {
    navigate("/admin/registered_users");
  };

  const filteredData = userTableData
    .filter(
      (row) =>
        filterOption === "All" ||
        (row.status === "ACTIVE") === (filterOption === "ACTIVE")
    )
    .filter(
      (row) =>
        row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        row.phoneNo?.includes(searchText) ||
        row.createdAt?.toLowerCase().includes(searchText.toLowerCase())
    );

  // const filteredData = userTableData
  //   .filter(
  //     (row) =>
  //       filterOption === "All" ||
  //       (row.status === "ACTIVE") === (filterOption === "ACTIVE") ||
  //       (row.status === "PENDING") === (filterOption === "PENDING") ||
  //       (row.status === "DEACTIVATED") === (filterOption === "DEACTIVATED")
  //   )
  //   .filter(
  //     (row) =>
  //       row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
  //       row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
  //       row.phoneNo?.includes(searchText) ||
  //       row.createdAt?.toLowerCase().includes(searchText.toLowerCase())
  //   );

  const handleRowClick = (event, rowData) => {
    // Do something with the clicked row data, e.g., navigate to a detail page
    console.log("Clicked row data:", rowData);
    setEachRowData(rowData);

    setShowInfo(true);
  };

  const handleRowClose = () => {
    setShowInfo(false);
  };

  return (
    <AdminBodyMargin>
      <div className="overview_header_body">
        <div className="overview_header_left_body">
          <div className="overview_header_left_bigtext">
            Welcome Back, {userData?.userDto?.lastName}
          </div>
          <div className="overview_header_left_date">
            {currentDateTime}
            {/* 14 Sep 2023 - 09:13 AM */}
          </div>
        </div>
        <div className="overview_header_right_btn">
          <div className="overview_header_btn_text">Downloaded Report</div>
          <div className="overview_header_btn_icon">
            <img src={AllImages.download_icon} />
          </div>
        </div>
      </div>

      <div className="overview_card_gen_body">
        <OverviewCard
          total={userTableData?.length}
          cardText="Number of Registered Users"
        />
        <OverviewCard
          total={drawData?.length}
          cardText="Number of Active Opportunity Drawings"
        />
        <OverviewCard
          total={activeUserCount}
          cardText="Number of Active Subscribers"
        />
      </div>

      <div
        style={{
          marginTop: "90px",
          borderTop: "1px solid #353535",
          paddingTop: "50px",
        }}
      >
        <div className="overview_table_title_body">
          <div className="overview_table_title_text">Registered User</div>
          <div className="registered_search_body">
            <div className="registered_search_icon">
              <img src={AllImages.searchAdmin} />
            </div>
            <input
              type="text"
              placeholder="Search users"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="overview_table_title_right">
            <div className="overview_table_title_rightText">Filter by:</div>
            <div className="overview_table_title_selectBody">
              <select
                onChange={(e) => {
                  setFilterOption(e.target.value);
                  console.log(filterOption, "FGilter");
                }}
                value={filterOption}
              >
                <option value="All">All</option>
                <option value="ACTIVE">Active</option>
                <option value="PENDING">Pending</option>
                {/* <option value="DEACTIVATED">Deactivated</option> */}
              </select>
            </div>
            <div
              onClick={handleSeeMorebtn}
              className="overview_table_title_seeText"
            >
              See All
            </div>
          </div>
        </div>
        {filteredData.length === 0 ? (
          <div className="no_data_message">No matching records found</div>
        ) : (
          <TableComp
            handleRowClick={handleRowClick}
            tableColumn={columns}
            tableData={filteredData}
          />
        )}
      </div>

      {showInfo && (
        <InfoModal handleCloseModal={handleRowClose}>
          <div className="registered_single_user_header">
            <div className="registered_single_user_header_text">
              User Profile
            </div>
            <div
              onClick={handleRowClose}
              className="registered_single_user_header_btn"
            >
              X
            </div>
          </div>

          <div className="registered_single_user_pic_body">
            <div className="registered_single_user_pic">DR</div>
            <div
              className="registered_single_user_status"
              style={{
                color: eachRowData.status === "ACTIVE" ? "#14cd69" : "red",
              }}
            >
              {/* {eachRowData.status ? "Active" : "Deactivated"} */}
              {eachRowData.status}
            </div>
          </div>

          <div className="registered_single_user_line"></div>

          <div className="registered_single_user_infobody">
            <div className="registered_single_user_info_namebody">
              <div className="registered_single_user_info_nametitle">
                Full Name
              </div>
              <div className="registered_single_user_info_name">
                {eachRowData.firstName} {eachRowData.lastName}
              </div>
            </div>

            <div className="registered_single_user_info_phonebody">
              <div className="registered_single_user_info_phonetitle">
                Phone Number
              </div>
              <div className="registered_single_user_info_phone">
                {eachRowData.phoneNo}
              </div>
            </div>
          </div>

          <div className="registered_single_user_infobody">
            <div className="registered_single_user_info_namebody">
              <div className="registered_single_user_info_nametitle">
                Email Address
              </div>
              <div className="registered_single_user_info_name">
                {eachRowData.email}
              </div>
            </div>

            <div className="registered_single_user_info_phonebody">
              <div className="registered_single_user_info_phonetitle">
                Registered Date
              </div>
              <div className="registered_single_user_info_phone">
                {formattedDate(eachRowData.createdAt)}
                {/* {eachRowData.createdAt} */}
              </div>
            </div>
          </div>
          <div className="registered_single_user_deactive_btn">
            Deactivate Account
          </div>
        </InfoModal>
      )}
    </AdminBodyMargin>
  );
};

export default Overview;
