import React, { useState, useEffect } from "react";
import "./homeFourthSec.css";
import AllImages from "../../utils/dataList/AllImages";
import HomeSideMarginTrans from "../homeSideMarginTrans/HomeSideMarginTrans";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, x: 100 },
};

const squareVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 0 },
};

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 100 },
};

const HomeFourthSec = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <HomeSideMarginTrans>
      <div className="homeFourth_secComp">
        <div className="homeFourth_flexLeft">
          <img src={AllImages.fourthImg} />
        </div>

        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsX}
          className="homeFourth_flexRight"
        >
          <div className="homeFourth_flexRightTextOne">
            Download MyCarrus mobile application
          </div>
          <div className="homeFourth_flexRightTextTwo">
            Our user-friendly mobile app provides a convenient platform for
            users to register, select their preferred subscription plan, and
            join the opportunity draw.
          </div>

          <div className="homeFourth_secBtnGEn">
            <a
              href="https://play.google.com/store/apps/details?id=com.mycarrus&hl=en&gl=US"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="homeFourth_flexRightGBtn"
            >
              <img src={AllImages.fourthGooglebtn} />
            </a>
            <a
              href="https://apps.apple.com/us/app/my-carrus/id6473939397"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="homeFourth_flexRightABtn"
            >
              <img src={AllImages.fourthAppbtn} />
            </a>
          </div>
        </motion.div>
      </div>
      <div className="homeFourth_Footer_body">
        At MyCarrus, we believe that everyone deserves a chance to own their
        dream vehicle. Join our opportunity draw platform and let us make your
        automotive dreams a reality.
      </div>
    </HomeSideMarginTrans>
  );
};

export default HomeFourthSec;
