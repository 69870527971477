import React, { useState, useEffect } from "react";
import "./registeredUsers.css";
import AdminBodyMargin from "../../../containers/adminBodyMargin/AdminBodyMargin";
import TableComp from "../../../containers/table/TableComp";
import AllImages from "../../../utils/dataList/AllImages";
import NewModal from "../../../containers/newModal/NewModal";
import InfoModal from "../../../containers/infoModal/InfoModal";
import { hideLoader, showLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";
import moment from "moment";
import { format } from "date-fns";

const lookup = { true: "Active", false: "Deactivated" };

const RegisteredUsers = () => {
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("All");
  const [tab, setTab] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [eachRowData, setEachRowData] = useState();
  const [userTableData, setUserTableData] = useState([]);

  const formattedDate = (dateString) => {
    const date = moment(dateString);
    return date.format("Do MMMM, YYYY");
  };

  const handleTabZero = () => {
    setTab(0);
  };

  const handleTabOne = () => {
    setTab(1);
  };

  const data = [
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "James Redd",
      emailAddress: "james.re@gmail.com",
      phoneNumber: "1890",
      registeredDate: "5th, Oct, 2022",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Foxx David",
      emailAddress: "david.fo@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
    {
      fullName: "Benjamin Thomas",
      emailAddress: "benj.re@gmail.com",
      phoneNumber: "1960",
      registeredDate: "5th, Oct, 2021",
      // status: "Active",
      status: false,
    },
    {
      fullName: "Daniel Regal",
      emailAddress: "daniel.re@gmail.com",
      phoneNumber: "1987989888",
      registeredDate: "5th, Oct, 2023",
      // status: "Active",
      status: true,
    },
  ];

  const getUsersData = async () => {
    showLoader();

    const res = await axiosCalls("api/v1/user", "GET");

    if (res) {
      // userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        return;
      }

      hideLoader();
      console.log(res, "ffff");

      const resData = res?.data?.content;
      // setUser(resData);
      // const jsonString = JSON.stringify(resData);
      // localStorage.setItem("Overview", jsonString);
      setUserTableData(resData);
      // Calculate the active user count
      // const activeUsers = resData.filter((user) => user.status === "ACTIVE");
      // setActiveUserCount(activeUsers.length);

      console.log(">>>>>>>>>>Res", resData?.content);
    }
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const handleRowClick = (event, rowData) => {
    // Do something with the clicked row data, e.g., navigate to a detail page
    console.log("Clicked row data:", rowData);
    setEachRowData(rowData);

    setShowInfo(true);
  };

  const handleCloseModal = () => {
    setShowInfo(false);
  };

  const filteredData =
    tab === 0
      ? userTableData.filter(
          (row) =>
            row.status === "ACTIVE" &&
            (row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
              row.email.toLowerCase().includes(searchText.toLowerCase()))
        )
      : userTableData.filter(
          (row) =>
            row.status === "DELETED" ||
            (row.status === "PENDING" &&
              (row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                row.email.toLowerCase().includes(searchText.toLowerCase())))
        );

  const columns = [
    {
      title: "User full name",
      field: "fullName",

      // align: "center",
      render: (rowData) => (
        // <RowDetails
        //   text={rowData.name}
        //   detailsText={rowData.email}
        //   rowImg={rowData.img}
        // />

        <div className="managed_users_table_role_body">
          <div className="manage_user_body_details_image">
            {rowData.firstName}
          </div>

          <div className="managed_users_table_role_text">
            {rowData.lastName}
          </div>
        </div>
      ),
    },
    { title: "Email address", field: "email" },
    { title: "Phone number", field: "phoneNo" },
    {
      title: "Registered Date",
      field: "createdAt",
      render: (rowData) => {
        const date = new Date(rowData.createdAt);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("en-GB", options);
      },
    },
    {
      title: "Status",
      field: "status",
      // lookup,
      render: (rowData) => (
        <span
          style={{
            color:
              rowData.status === "ACTIVE"
                ? "#14CD69"
                : rowData.status === "PENDING"
                ? "orange"
                : "red",
          }}
        >
          {rowData.status}
        </span>
      ),
    },
  ];

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <AdminBodyMargin>
        <div className="registered_users_heading">Registered User</div>
        <div className="registered_user_nav_body">
          <div className="registered_user_nav_absolute">
            <div className=" registered_user_nav_absoluteFlex_left">
              <div
                onClick={handleTabZero}
                className={
                  tab === 0
                    ? "registered_user_act_text_active"
                    : "registered_user_act_text"
                }
              >
                Active Users
              </div>
              <div
                onClick={handleTabOne}
                className={
                  tab === 1
                    ? "registered_user_act_text_active"
                    : "registered_user_act_text"
                }
              >
                Non Active Users
              </div>
            </div>

            <div className="registered_user_nav_absoluteFlex_right">
              <div className="registered_search_body">
                <div className="registered_search_icon">
                  <img src={AllImages.searchAdmin} />
                </div>
                <input
                  placeholder="Search users"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>

              {/* <div className="registered_table_title_selectBody_flex">
              <div className="registered_table_title_rightText">Filter by:</div>
              <div className="registered_table_title_selectBody">
                <select>
                  <option>Active</option>
                  <option>Deactivated</option>
                </select>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        {tab === 0 && (
          <>
            {filteredData.length === 0 ? (
              <div className="no_data_message">
                No matching records found in active users
              </div>
            ) : (
              <TableComp
                handleRowClick={handleRowClick}
                tableColumn={columns}
                tableData={filteredData}
              />
            )}
          </>
        )}

        {tab === 1 && (
          <>
            {filteredData.length === 0 ? (
              <div className="no_data_message">
                No matching records found in deactivated users
              </div>
            ) : (
              <TableComp
                handleRowClick={handleRowClick}
                tableColumn={columns}
                tableData={filteredData}
              />
            )}
          </>
        )}
      </AdminBodyMargin>

      {showInfo && (
        <InfoModal handleCloseModal={handleCloseModal}>
          <div className="registered_single_user_header">
            <div className="registered_single_user_header_text">
              User Profile
            </div>
            <div className="registered_single_user_header_btn">X</div>
          </div>

          <div className="registered_single_user_pic_body">
            <div className="registered_single_user_pic">DR</div>
            <div
              className="registered_single_user_status"
              style={{ color: eachRowData.status ? "#14cd69" : "red" }}
            >
              {eachRowData.status ? "Active" : "Deactivated"}
            </div>
          </div>

          <div className="registered_single_user_line"></div>

          <div className="registered_single_user_infobody">
            <div className="registered_single_user_info_namebody">
              <div className="registered_single_user_info_nametitle">
                Full Name
              </div>
              <div className="registered_single_user_info_name">
                {eachRowData.firstName} {eachRowData.lastName}
              </div>
            </div>

            <div className="registered_single_user_info_phonebody">
              <div className="registered_single_user_info_phonetitle">
                Phone Number
              </div>
              <div className="registered_single_user_info_phone">
                {eachRowData.phoneNo}
              </div>
            </div>
          </div>

          <div className="registered_single_user_infobody">
            <div className="registered_single_user_info_namebody">
              <div className="registered_single_user_info_nametitle">
                Email Address
              </div>
              <div className="registered_single_user_info_name">
                {eachRowData.email}
              </div>
            </div>

            <div className="registered_single_user_info_phonebody">
              <div className="registered_single_user_info_phonetitle">
                Registered Date
              </div>
              <div className="registered_single_user_info_phone">
                {formattedDate(eachRowData.createdAt)}

                {/* {eachRowData.createdAt} */}
              </div>
            </div>
          </div>
          <div className="registered_single_user_deactive_btn">
            Deactivate Account
          </div>
        </InfoModal>
      )}
    </>
  );
};

export default RegisteredUsers;
