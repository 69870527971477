import React, { useState } from "react";
import "./newPassword.css";
import AuthScreenParent from "../../../containers/authScreenParent/AuthScreenParent";
import AllImages from "../../../utils/dataList/AllImages";
import PasswordTag from "../../../containers/passwordTag/PasswordTag";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";
import BackToLogin from "../../../containers/backToLogin/BackToLogin";
import NewPasswordCheck from "../newPasswordCheck/NewPasswordCheck";

const NewPassword = () => {
  const [newPasswordCheck, setNewPasswordCheck] = useState(false);

  const handleNewPasswordCheck = () => {
    setNewPasswordCheck(true);
  };

  const handleNewPasswordClose = () => {
    setNewPasswordCheck(false);
  };
  return (
    <>
      <AuthScreenParent>
        <div className="newPassword_IconTop">
          <img src={AllImages.key} />
        </div>

        <div className="newPassword_Texttt">Set new password</div>
        <div className="newPasword__subText">
          Your new password must be different to your previous used passwords.
        </div>

        <PasswordTag passLabel="Password *" passPlaceHolder="Enter Password" />
        <div className="newPAssword__Passwtext">
          Must be at least 8 characters.
        </div>

        <PasswordTag
          passLabel="Confirm Password *"
          passPlaceHolder="Enter Confirm Password"
        />

        <div className="newPassword__resetBtnMargin">
          <DefaultLoginBtn
            handleBtnClick={handleNewPasswordCheck}
            btnText="Reset Password"
          />
        </div>
        <BackToLogin />
      </AuthScreenParent>

      {newPasswordCheck && (
        <NewPasswordCheck closeNewModal={handleNewPasswordClose} />
      )}
    </>
  );
};

export default NewPassword;
