import React, { useEffect, useState } from "react";
import "./comingSoon.css";
import HomeSideMargin from "../../../containers/homeSideMargin/HomeSideMargin";
import Header from "../../../containers/header/Header";
import Footer from "../../../containers/footer/Footer";
import { useLocation } from "react-router-dom";

const ComingSoon = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <HomeSideMargin>
        <Header />
        <div className="comingSoon_page">Coming Soon</div>
      </HomeSideMargin>

      <Footer />
    </div>
  );
};

export default ComingSoon;
