import React, { useState } from "react";

import "./leftNav.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import AllImages from "../../utils/dataList/AllImages";

const LeftNav = ({ open, openLogoutBtn, closeLogoutBtn, openLogoutPage }) => {
  const location = useLocation();

  const { pathname } = location;
  const navigate = useNavigate();

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="cont-main">
            <Link
              to="/"
              className={
                splitLocation[1] === ""
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Home
            </Link>
            <div className="navBar_gradient_line"></div>

            <Link
              // to="/services"
              className={
                splitLocation[1] === "services"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Subscription
            </Link>

            <div className="navBar_gradient_line"></div>

            <Link
              to="/support"
              className={
                splitLocation[1] === "support"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Support
            </Link>

            <div className="navBar_gradient_line"></div>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.mycarrus&hl=en&gl=US"
              // to="/allproducts"
              className={
                splitLocation[1] === "allproducts"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              <img src={AllImages.googleHeadButton} />
            </a>

            <a
              href="https://apps.apple.com/us/app/my-carrus/id6473939397"
              target="_blank"
              // to="/allproducts"
              className={
                splitLocation[1] === "allproducts"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              <img src={AllImages.appStoreHeadButton} />
            </a>
            {/* <Link
              to="/training"
              className={
                splitLocation[1] === "training"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              TRAINING CENTER
            </Link> */}

            <div
              // to="/contact_page"
              // className={
              //   splitLocation[1] === "contact_page"
              //     ? "leftNavbar-opt-active"
              //     : "leftNavbar-opt"
              // }
              style={{ textDecoration: "none", paddingBottom: "300px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNav;
