import {
  Adminpage,
  ComingSoonPage,
  DeleteMobileAccountPage,
  DrawingsPage,
  ForgotPasswordpage,
  Homepage,
  InviteAdminSetupPage,
  Loginpage,
  MessagePage,
  NewPasswordpage,
  NotificationPage,
  OverviewPage,
  PartnersPage,
  PrivacyPolicypage,
  RegisteredUsersPage,
  SettingsPage,
  SubscriptionsPage,
  SupportScreenPage,
  TermsConditionPage,
  ViewAllAdminPage,
} from "./pages";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

const RoutesE = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/comingsoon",
      element: <ComingSoonPage />,
    },
    {
      path: "/admin",
      element: <Adminpage />,
    },
    {
      path: "admin/login",
      element: <Loginpage />,
    },

    {
      path: "admin/forgot_password",
      element: <ForgotPasswordpage />,
    },

    {
      path: "admin/new_password",
      element: <NewPasswordpage />,
    },

    {
      path: "/privacy_policy",
      element: <PrivacyPolicypage />,
    },

    {
      path: "/admin/overview",
      element: <OverviewPage />,
    },

    {
      path: "/admin/registered_users",
      element: <RegisteredUsersPage />,
    },

    {
      path: "/admin/drawings",
      element: <DrawingsPage />,
    },

    {
      path: "/terms_conditions",
      element: <TermsConditionPage />,
    },

    {
      path: "/support",
      element: <SupportScreenPage />,
    },

    {
      path: "/delete-mobile-account",
      element: <DeleteMobileAccountPage />,
    },
    {
      path: "/admin/partners",
      element: <PartnersPage />,
    },

    {
      path: "/admin/settings/invite_admin/:token",
      element: <InviteAdminSetupPage />,
    },

    {
      path: "/admin/settings/view_admin",
      element: <ViewAllAdminPage />,
    },
    {
      path: "/admin/message",
      element: <MessagePage />,
    },
    {
      path: "/admin/notification",
      element: <NotificationPage />,
    },

    {
      path: "/admin/subscriptions",
      element: <SubscriptionsPage />,
    },

    {
      path: "/admin/settings",
      element: <SettingsPage />,
    },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <RoutesE />
    </Router>
  );
};

export default AppWrapper;
