import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import "./index.css";
import JWT from "jwt-decode";

export const ProtectedLayout = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    handleAuth();
  }, [auth]);

  const handleAuth = () => {
    let token = localStorage.getItem("token");
    if (token == "" || token == undefined || token == null) {
      return setAuth(false);
    }
    validateToken(token);
  };

  const validateToken = () => {
    try {
      let token_expired = localStorage.getItem("token");
      let decoded = JWT(token_expired);

      console.log("decoded>>>>>", decoded);
      console.log("user email...", decoded?.email);
      localStorage.setItem("User Role", decoded?.role);
      console.log("user role", decoded?.role);

      let decode_string = decoded["exp"];
      var current_time = Date.now() / 1000;
      if (decode_string < current_time) {
        localStorage.clear();
        setAuth(false);
        window.location.href = "/admin/login";
      } else {
        setAuth(true);
      }
    } catch (e) {
      localStorage.clear();
      setAuth(false);
      //what you need to do incase the jwt is not valid
      console.log(e); //for your own debugging
    }
  };

  return (
    <div id="header">
      <Sidebar />

      <Navbar />
      <div className="layout__content">
        {auth ? children : navigate(`/admin/login`)}
      </div>
    </div>
  );
};
