import React, { useState, useEffect } from "react";
import "./navbar.css";
import AllImages from "../../utils/dataList/AllImages";

const Navbar = () => {
  const [userData, setUserData] = useState({});

  const getObjectFromLocalStorage = () => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem("res");

    // Parse the JSON string to an object
    const storedObject = JSON?.parse(jsonString);
    setUserData(storedObject);
    console.log(userData, "User Data");
    // Use the storedObject in your component
  };

  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);

  // Get the first letter of the first name
  const firstInitial = userData?.userDto?.firstName
    ? userData?.userDto?.firstName?.charAt(0).toUpperCase()
    : "";

  // Get the first letter of the last name
  const lastInitial = userData?.userDto?.lastName
    ? userData?.userDto?.lastName?.charAt(0).toUpperCase()
    : "";

  // Combine the first initials
  const initials = firstInitial + lastInitial;

  return (
    <div className="navbar_body">
      <div className="navbar_body_header">
        <img src={AllImages.admin_logo} />
      </div>

      <div className="navbar_body_info_body">
        <div className="navbar_body_notify">
          <img src={AllImages.bell_icon} />

          <div className="navbar_notifications_num">2</div>
        </div>

        <div className="navbar_body_details">
          {/* <div className="navbar_body_details_image_body"> */}
          <div className="navbar_body_details_image">{initials}</div>
          {/* </div> */}

          <div className="navbar_body_details_info">
            <div className="navbar_body_details_name">
              {userData?.userDto?.firstName} {userData?.userDto?.lastName}
            </div>

            <div className="navbar_body_details_email">
              {userData?.userDto?.role === "SUPERADMIN"
                ? "Super Admin"
                : "Admin"}
            </div>
          </div>
        </div>
        {/* <div className="navbar_body_anchor_tag">
          <img src={Helpers.admin_anchor} />
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
