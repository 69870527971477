import React, { useState, useEffect } from "react";
import "./drawingsCard.css";
import AllImages from "../../utils/dataList/AllImages";

const DrawingsCard = ({
  car_logo,
  car_image,
  draw_time,
  current_entry,
  end_date,
  handleOpenEachActiveDrawing,
  eachIndex,
}) => {
  // Function to format time
  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    return {
      hours: parseInt(hours),
      minutes: parseInt(minutes),
      seconds: parseInt(seconds),
    };
  };

  // State to store the countdown time
  const [countdown, setCountdown] = useState(formatTime(draw_time));

  // Use the formatted time in your JSX
  const formattedTime = countdown;

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the time remaining
      const now = new Date();
      const endDate = new Date(end_date);
      const timeRemaining = Math.max(0, endDate - now);

      // Calculate hours, minutes, and seconds from milliseconds
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown({
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });

      // If the countdown reaches zero, clear the interval
      if (timeRemaining <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [end_date]); // Re-run the effect when end_date changes

  const formattedEndDate = new Date(end_date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div
      id={eachIndex}
      className="drawings_card_body"
      onClick={handleOpenEachActiveDrawing}
    >
      <div className="drawings_card_body_child">
        <div className="drawings_card_car_logo">
          <img src={car_logo} alt="Car Logo" />
        </div>

        <div className="drawings_card_car_img">
          <img src={car_image} alt="Car Image" />
        </div>

        <div className="drawings_card_time_body">
          <div className="drawings_card_time_card">
            <div className="drawings_card_time_hour">{formattedTime.hours}</div>
            <div className="drawings_card_time_hourText">Hour</div>
          </div>
          <div className="drawings_card_time_columnText">:</div>
          <div className="drawings_card_time_card">
            <div className="drawings_card_time_hour">
              {formattedTime.minutes}
            </div>
            <div className="drawings_card_time_hourText">Mins</div>
          </div>
          <div className="drawings_card_time_columnText">:</div>
          <div className="drawings_card_time_card">
            <div className="drawings_card_time_hour">
              {formattedTime.seconds}
            </div>
            <div className="drawings_card_time_hourText">Secs</div>
          </div>
        </div>

        <div className="drawings_sub_card_body">
          <div className="drawings_sub_card_current">
            <div className="drawings_sub_card_entry">Current Entry:</div>
            <div className="drawings_sub_card_users">{current_entry} Users</div>
          </div>
          <div className="drawings_sub_card_current">
            <div className="drawings_sub_card_entry">Entry Ends:</div>
            <div className="drawings_sub_card_users">{formattedEndDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawingsCard;
