import React, { useState, useEffect } from "react";
import "./homeFirstSec.css";
import AllImages from "../../utils/dataList/AllImages";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router";

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, x: 100 },
};

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 100 },
};

const HomeFirstSec = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      {/* <div className="homezero_dollarrr">$0</div> */}
      <div className="homefirst_sec_container">
        <div className="homeFirst_leftContainer">
          <div className="homeFirst_leftContainer_text">
            <span className="homeFirst_leftContainer_textSpan">100%</span> Free
            Monthly <br />
            <span className="homeFirst_leftContainer_textSpanDoll">$0 </span>
            Brand New Vehicle Giveaway
          </div>

          <div className="homeFirst_leftContainer_textTwo">
            Exciting monthly vehicle Give Away with $0 free application and
            participation. Each month, a different vehicle model will be up for
            grabs, ranging from luxury sedans to powerful SUVs.
          </div>
          <div className="homeFirst_leftContainer_AppBtnCont">
            <a
              href="https://play.google.com/store/apps/details?id=com.mycarrus&hl=en&gl=US"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="homeFirst_leftContainer_AppBtnGoogle"
            >
              <img src={AllImages.googleBtnSec} />
            </a>
            <a
              href="https://apps.apple.com/us/app/my-carrus/id6473939397"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="homeFirst_leftContainer_AppBtnApple"
            >
              <img src={AllImages.appleBtnSec} />
            </a>
          </div>
        </div>

        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsX}
          className="homeFirst_rightContainer"
        >
          <img src={AllImages.carrusFirstSecImg} />
        </motion.div>
      </div>
    </>
  );
};

export default HomeFirstSec;
