import React from "react";
import "./footer.css";
import HomeSideMarginTrans from "../homeSideMarginTrans/HomeSideMarginTrans";
import AllImages from "../../utils/dataList/AllImages";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavPolicy = () => {
    navigate("/privacy_policy");
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleTermsConditions = () => {
    navigate("/terms_conditions");
  };
  const handleSupportScreen = () => {
    navigate("/support");
  };

  const handleComingSoonScreen = () => {
    navigate("/comingsoon");
  };

  return (
    <HomeSideMarginTrans>
      <div className="footer_marginLineee__"></div>
      <div className="footer_bgd_heading">
        <div onClick={handleHome} className="footer_logo__">
          <img src={AllImages.whiteLogo} />
        </div>

        <div>
          <div className="footer_options_navCont">
            {/* <div className="footer_option__">Help</div> */}
            <div className="footer_option__" onClick={handleComingSoonScreen}>
              Subscription
            </div>
            <div className="footer_option__" onClick={handleSupportScreen}>
              Support
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.mycarrus&hl=en&gl=US"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="footer_option_img___"
            >
              <img src={AllImages.googleHeadButton} />
            </a>
            <a
              href="https://apps.apple.com/us/app/my-carrus/id6473939397"
              target="_blank"
              style={{ textDecoration: "none" }}
              className="footer_option_img___"
            >
              <img src={AllImages.appStoreHeadButton} />
            </a>
          </div>
          <div className="footerTermsGenn">
            <div onClick={handleNavPolicy} className="footer_option__term">
              Privacy Policy
            </div>
            <div
              onClick={handleTermsConditions}
              className="footer_option__term"
            >
              Terms & Conditions
            </div>
            {/* <div className="footer_option__term">FAQ</div> */}
          </div>
        </div>
      </div>
    </HomeSideMarginTrans>
  );
};

export default Footer;
