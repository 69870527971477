import React from "react";
import "./defaultLoginBtn.css";

const DefaultLoginBtn = ({ width, btnText, handleBtnClick, type }) => {
  return (
    <button
      type={type}
      onClick={handleBtnClick}
      style={{ width }}
      className="default_loginBtn"
    >
      {btnText}
    </button>
  );
};

export default DefaultLoginBtn;
