import React, { useState, useEffect } from "react";
import "./sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";

import SidebarOption from "../../containers/sidebarOption/SidebarOption";
import AllImages from "../../utils/dataList/AllImages";
import InfoModal from "../../containers/infoModal/InfoModal";

const Sidebar = () => {
  const [isHovered, setHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const [showSettings, setShowSettings] = useState("");

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleLogoutModal = () => {
    setLogoutModal(true);
  };
  const logoutFunc = () => {
    localStorage.clear();
    navigate("/admin/login");
    // window.location.href = "/login";
  };

  const getObjectFromLocalStorage = () => {
    // Retrieve the JSON string from localStorage
    const jsonString = localStorage.getItem("role");

    // Parse the JSON string to an object
    // const storedObject = JSON.parse(jsonString);
    setShowSettings(jsonString);

    // Use the storedObject in your component
  };

  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);

  return (
    <>
      <div className="sidebar_body">
        <div style={{ width: "100%" }}>
          <SidebarOption
            path="/admin/overview"
            icon={AllImages.overview_icon}
            text="Overview"
            onClickFunc={() => {
              navigate("/admin/overview");
            }}
          />

          <SidebarOption
            path="/admin/registered_users"
            icon={AllImages.users_icon}
            text="Registered Users"
            onClickFunc={() => {
              navigate("/admin/registered_users");
            }}
          />

          <SidebarOption
            path="/admin/drawings"
            icon={AllImages.drawings_icon}
            text="Drawings"
            onClickFunc={() => {
              navigate("/admin/drawings");
            }}
          />
          <SidebarOption
            path="/admin/partners"
            icon={AllImages.partners_icon}
            text="Partners"
            onClickFunc={() => {
              navigate("/admin/partners");
            }}
          />

          <SidebarOption
            path="/admin/message"
            icon={AllImages.message_icon}
            text="Message"
            onClickFunc={() => {
              navigate("/admin/message");
            }}
          />
          <SidebarOption
            path="/admin/notification"
            icon={AllImages.notifications_icon}
            text="Notification"
            onClickFunc={() => {
              navigate("/admin/notification");
            }}
          />
          <SidebarOption
            path="/admin/subscriptions"
            icon={AllImages.subscriptions_icon}
            text="Subscriptions"
            onClickFunc={() => {
              navigate("/admin/subscriptions");
            }}
          />
        </div>

        <div className=" sidebar_split_section">
          {showSettings === "SUPERADMIN" ? (
            <SidebarOption
              path="/admin/settings"
              icon={AllImages.settings_icon}
              text="Settings"
              onClickFunc={() => {
                navigate("/admin/settings");
              }}
            />
          ) : null}

          <SidebarOption
            path="/admin/help"
            icon={AllImages.logout_icon}
            text="Log Out"
            onClickFunc={() => {
              handleLogoutModal();
            }}
          />
        </div>
      </div>

      {logoutModal && (
        <InfoModal
          handleCloseModal={() => {
            setLogoutModal(false);
          }}
        >
          <div className="logout_modal_body">
            <div className="logout_modal_headText">
              Are you sure you want to Logout?
            </div>

            <div className="logout_modal_btn_body">
              <div
                onClick={() => {
                  setLogoutModal(false);
                }}
                className="logout_modal_btn_no"
              >
                No
              </div>
              <div onClick={logoutFunc} className="logout_modal_btn_yes">
                Logout
              </div>
            </div>
          </div>
        </InfoModal>
      )}
    </>
  );
};

export default Sidebar;
