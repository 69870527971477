import React, { useState, useEffect } from "react";
import "./infoModal.css";

const InfoModal = ({ handleCloseModal, children, width, background }) => {
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };
  return (
    <div className="Indexmodal-container" onClick={handleCloseModal}>
      <div
        style={{ width, background }}
        onClick={handleChildClick}
        className="Indexmodal-content"
      >
        {children}
      </div>
    </div>
  );
};

export default InfoModal;
