import React, { useState } from "react";
import "./forgotPassword.css";
import AllImages from "../../../utils/dataList/AllImages";
import AuthScreenParent from "../../../containers/authScreenParent/AuthScreenParent";
import TextInput from "../../../containers/textInput/TextInput";
import DefaultLoginBtn from "../../../containers/defaultLoginBtn/DefaultLoginBtn";
import { useNavigate } from "react-router-dom";
import ForgotPaswordCheck from "../forgotPasswordCheck/ForgotPaswordCheck";
import BackToLogin from "../../../containers/backToLogin/BackToLogin";

const ForgotPassword = () => {
  const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    navigate("/admin/login");
  };

  return (
    <>
      <AuthScreenParent>
        <div className="forgotPassword_IconGen">
          <img src={AllImages.key} />
        </div>

        <div className="forgotPassword_textt"> Forgot Password?</div>
        <div className="forgotPassword_textSub">
          Kindly enter your email address and we will send you a reset
          instruction
        </div>

        <TextInput
          inputLabel="Email Address *"
          inputPlaceholder="John.doe@example.co"
          width="100%"
        />

        <div className="btnGen_passs">
          <DefaultLoginBtn
            handleBtnClick={() => {
              setForgotPasswordSent(true);
            }}
            btnText="Reset Password"
          />
        </div>

        <BackToLogin />
      </AuthScreenParent>

      {forgotPasswordSent && (
        <ForgotPaswordCheck
          closeNewModal={() => {
            setForgotPasswordSent(false);
          }}
        />
      )}
    </>
  );
};

export default ForgotPassword;
