import React, { useEffect, useState } from "react";
import "./homeSecondSec.css";
import CarBrandSec from "../carBrandSec/CarBrandSec";
import HomeSideMarginTrans from "../homeSideMarginTrans/HomeSideMarginTrans";
import SecondCompBody from "../secondCompBody/SecondCompBody";
import AllImages from "../../utils/dataList/AllImages";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, x: 100 },
};

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 100 },
};

const squareVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 0 },
};

const HomeSecondSec = () => {
  const controls = useAnimation();
  const navigate = useNavigate();
  const [ref, inView] = useInView();

  const handleComingSoonScreen = () => {
    navigate("/comingsoon");
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <HomeSideMarginTrans>
      <CarBrandSec />

      <div className="first_gradient_cirleSec"></div>
      <div className="second_gradient_cirleSec"></div>

      <div className="second_flexComBodyy">
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariants}
          className="second_flexLeftt"
        >
          <div className="second_flexLeftdouble">
            <SecondCompBody
              image={AllImages.compImgOne}
              heading="Exciting Opportunity"
              paraText="MyCarrus offers a unique chance to win your dream vehicle without the traditional financial burden of buying one."
            />
            <SecondCompBody
              image={AllImages.vehicleTwo}
              heading="Wide Range of Vehicles"
              paraText="With MyCarrus, you have the opportunity to win a vehicle from a diverse selection of reputable brands."
            />
          </div>

          <div className="second_flexLeftdouble">
            <SecondCompBody
              image={AllImages.subPack}
              heading="$0 subscription. Download and Apply on the app"
              paraText="Our subscription plans are designed to be $0, allowing you to participate in the opportunity draw for free."
            />
            <SecondCompBody
              image={AllImages.fairTrans}
              heading="Transparent and Fair Draw Process"
              paraText="With our cutting-edge technology, you can be confident that the winner is chosen randomly, giving everyone an equal chance to win."
            />
          </div>
        </motion.div>

        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsY}
          className="second_flexRightt"
        >
          <div className="second_flexRightTextOne">
            Unique chance to win your dream vehicle
          </div>
          <div className="second_flexRightTextTwo">
            MyCarrus is a revolutionary app that aims to provide an equal
            opportunity for individuals from all walks of life to own their
            dream vehicle for free. Our app organizes a monthly vehicle
            opportunity drawing where users have a chance to win a brand new
            vehicle of their choice.
          </div>
          <div className="second_flexRightTextTwo">
            By eliminating financial barriers, we aim to empower students,
            low-income individuals, and even those from high-class backgrounds
            to fulfill their dreams of owning a brand new vehicle.
          </div>

          <div
            onClick={handleComingSoonScreen}
            className="second_flexRightBtnn"
          >
            Check our packages
          </div>
        </motion.div>
      </div>
    </HomeSideMarginTrans>
  );
};

export default HomeSecondSec;
