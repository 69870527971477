import React from "react";
import AdminBodyMargin from "../adminBodyMargin/AdminBodyMargin";
import InfoModal from "../infoModal/InfoModal";
import AllImages from "../../utils/dataList/AllImages";
import PastDrawingCard from "../pastDrawingCard/PastDrawingCard";
import { pastDrawingCardInfo } from "../../utils/dataList/DataList";
import "./eachPastDrawingScreen.css";

const EachPastDrawingScreen = ({
  handleCloseEachPastDrawing,
  eachPastDrawingInfo,
}) => {
  const {
    car_logo,
    car_image,
    manufacture_year,
    color,
    winner_id,
    total_entries,
    engine_size,
    drawing_date,
    registered_city,
    car_make,
    car_model,
    condition,
    mileage,
    other_photo,
    description,
  } = eachPastDrawingInfo;
  return (
    <InfoModal
      background="#000"
      handleCloseModal={handleCloseEachPastDrawing}
      width="90%"
    >
      <div className="each_past_draw_heading_flex">
        <div className="each_past_draw_heading_flex_text">Drawing</div>
        <div className="each_past_draw_heading_btnBody">
          <div className="each_past_draw_heading_stop_btn">Stop Drawing</div>
          <div className="each_past_draw_heading_del_btn">Delete Drawing</div>
        </div>
      </div>

      <div className="each_past_draw_car_make_modelBackBody">
        <div
          onClick={handleCloseEachPastDrawing}
          className="each_past_draw_car_make_modelBackIcon"
        >
          <img src={AllImages.back_icon} />
        </div>
        <div className="each_past_draw_car_make_model_name">
          {manufacture_year} {car_make} {car_model}
        </div>
      </div>
      <div className="each_past_draw_bottom_line"></div>

      <div className="each_past_draw_left_rigthBody__">
        <PastDrawingCard
          car_image={car_image}
          car_logo={car_logo}
          winner_id={winner_id}
          total_entries={total_entries}
          drawing_date={drawing_date}
        />

        <div className="each_past_draw_rightBody">
          <div className="each_past_draw_right_makeText">
            {manufacture_year} {car_make} {car_model}
          </div>

          <div className="each_past_draw_right_make_info">{description}</div>
          <div className="each_past_drawing_detailsSec_b">
            <div className="each_past_drawing_details_mainColumn">
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">{car_make}</div>
                <div className="each_past_drawing_details_head">MAKE</div>
              </div>
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">
                  {manufacture_year}{" "}
                </div>
                <div>YEAR OF MANUFACTURE</div>
              </div>
            </div>
            <div className="each_past_drawing_details_mainColumn">
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">
                  {car_model}
                </div>
                <div className="each_past_drawing_details_head">MODEL</div>
              </div>
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">{color}</div>
                <div>COLOUR </div>
              </div>
            </div>
            <div className="each_past_drawing_details_mainColumn">
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">
                  {condition}
                </div>
                <div className="each_past_drawing_details_head">CONDITION</div>
              </div>
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">
                  {engine_size}
                </div>
                <div>ENGINE SIZE </div>
              </div>
            </div>
            <div className="each_past_drawing_details_mainColumn">
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">{mileage}</div>
                <div className="each_past_drawing_details_head">MILEAGE</div>
              </div>
              <div className="each_past_drawing_details_column">
                <div className="each_past_drawing_details_text">
                  {registered_city}
                </div>
                <div>REGISTERED CITY </div>
              </div>
            </div>
          </div>

          <div className="each_past_drawing_detailsSec_bottom_border"></div>
          <div className="each_past_drawing_details_eachImage_head">
            More Photos
          </div>
          <div className="each_past_drawing_details_body_flex">
            {other_photo?.map((images, index) => (
              <div className="each_past_drawing_details_eacImage">
                <img
                  src={images}
                  // style={{ width: "100%", objectFit: "cover" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </InfoModal>
  );
};

export default EachPastDrawingScreen;
