import React, { useState } from "react";
import "./passwordTag.css";
import AllImages from "../../utils/dataList/AllImages";

const PasswordTag = ({
  passPlaceHolder,
  passLabel,
  passValue,
  passName,
  passOnchange,
  width,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePassIcon = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="passwordTag_GenCont" style={{ width }}>
      <div className="passwordTag_Label"> {passLabel}</div>
      <div className="passwordTag_inputCont">
        <div className="passwordTag_input__">
          <input
            type={showPassword ? "text" : "password"}
            placeholder={passPlaceHolder}
            value={passValue}
            name={passName}
            onChange={passOnchange}
            required={required}
          />
        </div>
        <div onClick={handlePassIcon} className="passwordTag_icon">
          <img src={showPassword ? AllImages.closeeye : AllImages.closeeye} />
        </div>
      </div>
    </div>
  );
};

export default PasswordTag;
