import React, { useState, useEffect } from "react";
import "./addPartners.css";
import InfoModal from "../infoModal/InfoModal";
import AllImages from "../../utils/dataList/AllImages";
import TextInput from "../textInput/TextInput";
import { axiosCalls, axiosFormData } from "../../_api";
import { hideLoader, showLoader } from "../../loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPartners = ({ handleCloseDrawingForm }) => {
  const [image, setImage] = useState(null);
  const [drawInfo, setDrawInfo] = useState({
    carLogo: null,
    partnerName: "",
    email: "",
    phoneNumber: "",
  });

  // const handleImageChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     // Use FileReader to read the selected image as a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDrawInfo({
  //         ...drawInfo,
  //         image: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(selectedImage);
  //   }
  // };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setDrawInfo({
        ...drawInfo,
        image: selectedImage,
      });
    }
  };

  // const handleLogoChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   if (selectedImage) {
  //     // Use FileReader to read the selected image as a data URL
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setDrawInfo({
  //         ...drawInfo,
  //         carLogo: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(selectedImage);
  //   }
  // };

  const handleLogoChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setDrawInfo({
        ...drawInfo,
        carLogo: selectedImage,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrawInfo({
      ...drawInfo,
      [name]: value,
    });
  };

  const handleCreateDrawingForm = async (e) => {
    // e.preventDefault();

    // e.preventDefault();
    const data = {
      logo: drawInfo.carLogo,
      name: drawInfo.partnerName,
    };

    // Create a new FormData object
    // const formDataObject = new FormData();

    // Append form data to the FormData object
    // Object.entries(data).forEach(([key, value]) => {
    //   formDataObject.append(key, value);
    // });
    showLoader();
    const res = await axiosFormData("api/v1/site/config/partner", "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.message);
        toast.error(res?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      // window.location.href = "/";
      toast.success("Success", res?.data, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setTimeout(() => {
        window.location.href = "/admin/partners";
      }, 2000);
    }
  };

  //   const handleCreateDrawingForm = async (e) => {
  //     e.preventDefault();
  //     const data = {
  //       car_photo: drawInfo.image,
  //       brand_logo: drawInfo.carLogo,
  //       car_make: drawInfo.carMake,
  //       start_date: drawInfo.startDate,
  //       start_time: drawInfo.startTime,
  //       end_date: drawInfo.endDate,
  //       end_time: drawInfo.endTime,
  //       car_model: drawInfo.carModel,
  //       condition: drawInfo.condition,
  //       manufacture_year: drawInfo.makeYear,
  //       color: drawInfo.colour,
  //       description: drawInfo.carOverview,
  //       mileage: drawInfo.mileage,
  //       engine_size: drawInfo.engineSize,
  //       registered_city: drawInfo.registeredCity,
  //     };

  //     showLoader();

  //     try {
  //       const res = await axiosFormData("api/v1/entry/create", "POST", data);
  //       if (res) {
  //         hideLoader();
  //         if (res.er) {
  //           toast.error(res?.er?.data?.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "dark",
  //           });
  //           console.log(res.er);
  //           return;
  //         }
  //         console.log(">>>>>>>>RES", res?.data);

  //         toast.success("Created Draw successfully", res?.data, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });

  //         return;
  //         // if (res?.data?.role == "superadmin") {
  //         //   console.log(res.data);
  //         //   localStorage.setItem("token", res?.data?.token);
  //         //   window.location.href = `/dashboard`;
  //         //   NotificationManager.success("Login successful", "Welcome admin");
  //         //   return;
  //         // }
  //         // NotificationManager.error("Permission denied", "Error");
  //       }
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }

  //     // history.push(`/dashboard`);
  //     // NotificationManager.success("Login successfull", "Welcom admin");
  //   };

  return (
    <InfoModal width="60%" handleCloseModal={handleCloseDrawingForm}>
      <div className="add_partners_user_header">
        <div className="add_partners_user_header_text">Add Partners</div>
        <div
          onClick={handleCloseDrawingForm}
          className="add_partners_user_header_btn"
        >
          X
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          //   console.log(drawInfo, "drawing data");

          handleCreateDrawingForm();
        }}
        className="add_partners_user_form_body"
      >
        <div className="add_partners_user_form_right">
          <div className="add_partners__image_uploaded">
            <div className="add_partners__image_image__">
              {" "}
              <img
                style={{
                  objectFit: "contain",
                  height: "100%",

                  width: "101%",
                  borderRadius: "50%",
                }}
                src={
                  drawInfo.carLogo ? URL.createObjectURL(drawInfo.carLogo) : ""
                }
                // src={drawInfo.carLogo}
              />
            </div>
            <div className="add_partners__image_upload_icon">
              <img src={AllImages.imageIcon} />
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                required
              />
            </div>
          </div>

          <div className="add_partners__image_displa_text">Partner's Logo</div>
          <div className="add_partners__image_displa_text_sub">
            Must be a picture with transparent background
          </div>

          <TextInput
            inputLabel="Partner's Name *"
            inputName="partnerName"
            inputPlaceholder="Enter partner's name"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.partnerName}
            width="100%"
            required={true}
          />
          <TextInput
            inputLabel="Partner's email *"
            inputName="email"
            inputPlaceholder="Enter partner's email"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.email}
            width="100%"
            required={true}
          />
          <TextInput
            inputLabel="Partner's phone number *"
            inputName="phoneNumber"
            inputPlaceholder="Enter partner's phone number"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={drawInfo.phoneNumber}
            width="100%"
            required={true}
          />

          <button
            // onClick={(e) => {
            //   e.preventDefault();
            //   //   console.log(drawInfo, "drawing data");

            //   handleCreateDrawingForm();
            // }}
            className="add_partners_user_form_data_btn"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </InfoModal>
  );
};

export default AddPartners;
