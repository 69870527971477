import React, { useEffect, useState } from "react";
import "./homeThirdSec.css";
import HomeSideMarginTrans from "../homeSideMarginTrans/HomeSideMarginTrans";
import AllImages from "../../utils/dataList/AllImages";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariantsY = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: 0, x: 100 },
};

const squareVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 0 },
};

const squareVariantsX = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, y: 100 },
};

const HomeThirdSec = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <HomeSideMarginTrans>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="thirdSec_imageCont"
      >
        <img src={AllImages.thirdSecImg} />
      </motion.div>

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsX}
      >
        <div className="thirdSec_textOne">
          Unique reason to join the monthly Giveaway Draw with MyCarrus
        </div>

        <div className="thirdSec_textTwo">
          Our mission at MyCarrus is to provide an engaging and thrilling
          platform for individuals to have a realistic shot at winning their
          dream vehicles. We aim to bring joy and excitement to our customers'
          lives while creating a vibrant community of automotive enthusiasts.
        </div>
      </motion.div>
    </HomeSideMarginTrans>
  );
};

export default HomeThirdSec;
